<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import vuexStore from '@/vuex'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { AnalysisResult, AnalyticsResultsForComponentResultIds, SummaryKPIResult } from '@aedifion.io/aedifion-api'
import { InvalidValueError } from '@/utils/helpers/validate'
import { reportError } from '@/utils/helpers/errors'
import { showErrorNotification } from '@/utils/helpers/notifications'
import { FetchAnalysisResultPayload } from '@/vuex/analysis_instances/types'
import { isEnergyKPI, useAssetOverviewStore } from '@/stores/views/AssetOverview'
import type { PerSquareUnitKPIsKeys } from '@/stores/views/Reporting'
import useDefaultNumberOfColumns from '@/composables/useDefaultNumberOfColumns'
import AssetBaseCard from './AssetBaseCard.vue'
import AssetEnergyCard from './AssetEnergyCard.vue'
import DetailedContentCard from './DetailedContentCard.vue'
import TabbedContainer from '@/components/TabbedContainer/TabbedContainer.vue'
import TabbedContainerElement from '@/components/TabbedContainer/TabbedContainerElement.vue'

const { t, locale } = useI18n()

const analysisListLight = [
  'energy_cost_analysis',
  'co2_emission_analysis',
  'energy_consumption_analysis',
]

const analysisRequireListFull = [
  'energy_cost_analysis',
  'co2_emission_analysis',
  'energy_consumption_analysis',
  'wellbeing_analysis',
  'technical_availability_analysis',
]

const analysisAllowListFull = [
  'energy_cost_analysis',
  'co2_emission_analysis',
  'energy_consumption_analysis',
  'technical_availability_analysis',
  'wellbeing_analysis',
  'occupancy_rate_analysis',
]

function mapAnalysisKeyToKPIKey (kpiKey: string): PerSquareUnitKPIsKeys {
  switch (kpiKey) {
    case 'energy_cost_analysis':
      return 'energy_cost'
    case 'co2_emission_analysis':
      return 'co2_emissions'
    case 'energy_consumption_analysis':
      return 'energy_consumption'
    case 'technical_availability_analysis':
      return 'technical_availability'
    case 'wellbeing_analysis':
      return 'productivity'
    default:
      throw new Error(`Incorrect KPI key: ${kpiKey}`)
  }
}

const AssetComponent = computed(() => (kpiKey: PerSquareUnitKPIsKeys) => {
  if (isEnergyKPI(kpiKey)) {
    return AssetEnergyCard
  } else {
    return AssetBaseCard
  }
})

const assetBaseCardProps = computed(() => (kpiKey: string, isSelected: boolean) => {
  return {
    color: kpiAggregations.value.get(mapAnalysisKeyToKPIKey(kpiKey))?.color,
    kpi: mapAnalysisKeyToKPIKey(kpiKey),
    loading: loadingKPIAggregations.value,
    onClick: () => onFetchKpiAnalysisResult(kpiKey),
    selected: isSelected,
    subtitle: kpiAggregations.value.get(mapAnalysisKeyToKPIKey(kpiKey))?.subtitle,
    title: kpiAggregations.value.get(mapAnalysisKeyToKPIKey(kpiKey))?.title,
    unit: kpiAggregations.value.get(mapAnalysisKeyToKPIKey(kpiKey))?.unit,
    monthUnit: kpiAggregations.value.get(mapAnalysisKeyToKPIKey(kpiKey))?.monthUnit,
    value: kpiAggregations.value.get(mapAnalysisKeyToKPIKey(kpiKey))?.aggregationValue,
    series: kpiAggregations.value.get(mapAnalysisKeyToKPIKey(kpiKey))?.series,
  }
})

const analysisResult = computed<AnalysisResult|null>(() => vuexStore.getters['analysis_instances/analysisResult'])
const isLightVersion = computed<boolean>(() => vuexStore.getters['asset_overview/isLightVersion'])
const isLoadingAnalysisResult = computed<boolean>(() => vuexStore.getters['analysis_instances/isLoadingAnalysisResult'])
const projectId = computed<number|null>(() => vuexStore.getters['projects/currentProjectId'])

const analysisResults = computed<AnalyticsResultsForComponentResultIds[]>(() => vuexStore.getters['building_analyses/allAnalysesOfProject'](projectId.value) || [])

const kpiKeys = computed(() => {
  if (isLightVersion.value) {
    return analysisListLight
  } else {
    const result = new Set(analysisRequireListFull)
    analysisResults.value.forEach((item: AnalyticsResultsForComponentResultIds) => {
      if (item.alphanumeric_id && analysisAllowListFull.includes(item.alphanumeric_id)) {
        result.add(item.alphanumeric_id)
      }
    })
    return [...result]
  }
})

const selectedInstanceId = computed(() => vuexStore.state.asset_overview.selectedInstanceId)

const energyCostAnalysisResult = computed<AnalyticsResultsForComponentResultIds|null>(() => vuexStore.getters['building_analyses/analysisOfProject'](projectId.value, 'energy_cost_analysis'))

const hasEnergyCostCardBeenSelected = ref(false)

// #region AUTOMATICALLY SELECT ENERGY COST ANALYSIS CARD
async function fetchAnalysisResult (instanceId: string, resultId: string) {
  const payload: FetchAnalysisResultPayload = {
    instanceId: parseInt(instanceId),
    language: locale.value,
    resultId,
  }

  try {
    await vuexStore.dispatch('analysis_instances/fetchAnalysisResult', payload)
  } catch (error) {
    reportError(error)
  }
}

watch([projectId, energyCostAnalysisResult], ([newProjectId, newEnergyCostAnalysisResult], [oldProjectId, oldEnergyCostAnalysisResult]) => {
  if (newProjectId !== oldProjectId) hasEnergyCostCardBeenSelected.value = false

  if (newEnergyCostAnalysisResult === null) return

  if ((newEnergyCostAnalysisResult !== oldEnergyCostAnalysisResult) || hasEnergyCostCardBeenSelected.value === false) {
    fetchAnalysisResult(newEnergyCostAnalysisResult.instance_id!.toString(), newEnergyCostAnalysisResult.result_id!)
    hasEnergyCostCardBeenSelected.value = true
  }
})
// #endregion

// #region KPI CARDS
const assetOverviewStore = useAssetOverviewStore()
const { kpiAggregations, loading: loadingKPIAggregations } = storeToRefs(assetOverviewStore)

// #endregion
function getKpiResult (kpiKey: string): AnalyticsResultsForComponentResultIds|null {
  return vuexStore.getters['building_analyses/analysisOfProject'](projectId.value, kpiKey)
}

function summaryKPI (kpiKey: string): SummaryKPIResult|null {
  return getKpiResult(kpiKey)?.summary_kpi ?? null
}

function instanceIdOfKPI (kpiKey: string): number|null {
  return getKpiResult(kpiKey)?.instance_id ?? null
}

async function fetchKpiAnalysisResult (kpiKey: string, active: boolean): Promise<void> {
  if (!active) {
    const kpiResult: AnalyticsResultsForComponentResultIds | null = getKpiResult(kpiKey)
    if (!kpiResult) return
    const instanceId: number | undefined = kpiResult?.instance_id
    if (!instanceId && instanceId !== 0) return
    const resultId: string | undefined = kpiResult?.result_id
    if (!resultId) return

    const payload: FetchAnalysisResultPayload = {
      instanceId,
      language: locale.value,
      resultId,
    }

    try {
      await vuexStore.dispatch('analysis_instances/fetchAnalysisResult', payload)
    } catch (error) {
      if (error instanceof InvalidValueError) {
        showErrorNotification(`${t('notifications.errors.no_project_selected_with_attempted_action', { action: t('fetch_analysis_results') })}`)
      }
      reportError(error)
    }
  } else {
    vuexStore.commit('analysis_instances/SET_ANALYSIS_RESULT', null)
    vuexStore.commit('asset_overview/SET_SELECTED_INSTANCE_ID', null)
    vuexStore.commit('asset_overview/SET_SELECTED_RESULT_ID', null)
  }
}

function onFetchKpiAnalysisResult (kpiKey: string) {
  const tabId = instanceIdOfKPI(kpiKey)
  fetchKpiAnalysisResult(kpiKey, tabId === selectedInstanceId.value)
}

const isComponentMounted = ref(false)
const route = useRoute()

function getKpiKeyFromInstanceId (instanceId: string): string {
  const result = analysisResults.value.find((item: AnalyticsResultsForComponentResultIds) => item.instance_id === parseInt(instanceId))
  if (!result) return ''
  return result.alphanumeric_id!
}

onMounted(async () => {
  isComponentMounted.value = true
  const currentInstanceId = route.query.instance
  if (currentInstanceId) {
    const kpiKey = getKpiKeyFromInstanceId(currentInstanceId as string)
    await fetchKpiAnalysisResult(kpiKey, false)
  }
})

const { defaultNumberOfColumns } = useDefaultNumberOfColumns()
</script>

<template>
  <div>
    <TabbedContainer
      :value="selectedInstanceId"
      class="-tw-mx-2"
    >
      <MasonryWall
        :items="kpiKeys"
        :max-columns="defaultNumberOfColumns"
        :gap="24"
        class="tw-w-full"
      >
        <template #default="{ item }">
          <TabbedContainerElement
            :id="instanceIdOfKPI(item)"
            :active-tab-id="selectedInstanceId"
            :disabled="!summaryKPI(item)"
          >
            <template #tab="{ active }">
              <Component
                :is="AssetComponent(mapAnalysisKeyToKPIKey(item))"
                v-bind="assetBaseCardProps(item, active)"
              />
            </template>
            <template #content>
              <Teleport
                v-if="isComponentMounted"
                to="#detailed-content-card"
              >
                <DetailedContentCard
                  v-if="analysisResult"
                  :analysis-result="analysisResult"
                  class="mt-4"
                  :kpi-key="KPIKey"
                  :loading="isLoadingAnalysisResult"
                  :summary-kpi="summaryKPI(item)"
                />
              </Teleport>
            </template>
          </TabbedContainerElement>
        </template>
      </MasonryWall>
    </TabbedContainer>
    <div id="detailed-content-card" />
  </div>
</template>

<i18n lang="json" locale="de">
  {
    "fetch_analysis_results": "um die Analyseergebnisse zu laden"
  }
</i18n>

<i18n lang="json" locale="en">
  {
    "fetch_analysis_results": "to fetch the results of an analysis"
  }
</i18n>
