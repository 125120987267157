<!--
This component is a wrapper around <VueDatePicker />, to theme it and ensure
that it follows our design system.

You should be able to use it just like you’d use <VueDatePicker /> directly (or
almost), so you can refer to VueDatePicker’s documentation
(https://vue3datepicker.com/).

The only difference is that methods are accessed through the `vueDatePicker`
exposed object instead of the root of the component instance.
-->

<script setup lang="ts">
import '@vuepic/vue-datepicker/dist/main.css'
// ^ this is required every time that we use the VueDatePicker component
import { computed, ref, useSlots } from 'vue'
import VueDatePicker, { ModelValue as VueDatePickerModelValue } from '@vuepic/vue-datepicker'
import LabelWithArrowActivator from './LabelWithArrowActivator.vue'
import { useI18n } from 'vue-i18n'

type Props = {
  dayNames?: ((lang: string, weekStart: number) => string[]) | string[],
  disabled?: boolean,
  label?: string,
}

const { t, locale } = useI18n()

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
})

const model = defineModel<VueDatePickerModelValue>({ default: null })

const slots = useSlots()

// this component already defines those slots, so no need to pass them through
const SLOTS_WITH_DEFAULT_CONTENT = ['action-extra', 'action-row', 'calendar-header', 'calendar-icon', 'dp-input']

const passthroughSlots = Object.fromEntries(Object.entries(slots).filter((slot) => {
  return !SLOTS_WITH_DEFAULT_CONTENT.includes(slot[0]) && slot[1] !== undefined
}))

const dayNames = computed(() => props.dayNames || ((_lang: string, weekStart: number) => {
  const result = []

  const dayKeys = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

  for (let i = 0; i < dayKeys.length; i++) {
    const currentDayIndex = (weekStart + i) % dayKeys.length
    result.push(t(`dates.short_weekdays.${dayKeys[currentDayIndex]}`)[0])
  }

  return result
}))

const vueDatePicker = ref<InstanceType<typeof VueDatePicker> | null>(null)
defineExpose({ vueDatePicker })
</script>

<template>
  <VueDatePicker
    ref="vueDatePicker"
    v-model="model"
    class="datepicker"
    :clearable="false"
    :config="{ shadowDom: true }"
    :day-names="dayNames"
    :disabled="disabled"
    :locale="locale"
    v-bind="$attrs"
  >
    <template #dp-input="inputProps">
      <slot
        name="dp-input"
        v-bind="inputProps"
      >
        <LabelWithArrowActivator
          :disabled="disabled"
          :displayed-value="inputProps.value"
          :is-menu-open="inputProps.isMenuOpen"
          :label="label"
          @blur="inputProps.onBlur"
          @focus="inputProps.onFocus"
          @input="inputProps.onInput"
          @keydown.enter="inputProps.onEnter"
          @keydown.tab="inputProps.onTab"
          @keypress="inputProps.onKeypress"
          @paste="inputProps.onPaste"
        />
      </slot>
    </template>

    <template #action-row="actionRowProps">
      <slot
        name="action-row"
        v-bind="actionRowProps"
      >
        <div class="action-container">
          <slot
            name="action-extra"
            :internal-model-value="actionRowProps.internalModelValue"
            :select-current-date="actionRowProps.selectDate"
          />
          <div class="action-row">
            <v-btn
              class="tw-mr-2 bg-neutral-lighten5"
              data-testid="styleddatepicker-cancel-button"
              variant="outlined"
              color="primary-darken2"
              @click="actionRowProps.closePicker"
            >
              {{ t('actions.cancel') }}
            </v-btn>
            <v-btn
              color="primary-darken2"
              data-testid="styleddatepicker-save-button"
              :disabled="actionRowProps.disabled"
              @click="actionRowProps.selectDate"
            >
              {{ t('actions.save') }}
            </v-btn>
          </div>
        </div>
      </slot>
    </template>

    <template #calendar-header="{ index, day }">
      <slot
        name="calendar-header"
        :index="index"
        :day="day"
      >
        <div class="calendar-header-cell">
          {{ day }}
        </div>
      </slot>
    </template>

    <template #calendar-icon>
      <slot name="calendar-icon">
        <v-icon>
          far fa-calendar-week
        </v-icon>
      </slot>
    </template>

    <template
      v-for="(_, slot) of passthroughSlots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
  </VueDatePicker>
</template>

<style scoped lang="sass">
@use '@/scss/settings' as vuetifySettings

.datepicker, .datepicker :deep(.dp__theme_light)
  --dp-font-family: vuetifySettings.$body-font-family
  --dp-border-radius: 4px
  --dp-cell-size: 32px
  --dp-action-row-padding: 0
  --dp-row-margin: 10px 0
  --dp-multi-calendars-spacing: 56px
  --dp-menu-padding: 24px
  --dp-font-size: 0.875rem
  --dp-background-color: rgb(var(--v-theme-neutral-lighten5))
  --dp-text-color: rgb(var(--v-theme-neutral-darken3))
  --dp-hover-color: rgb(var(--v-theme-neutral-lighten3))
  --dp-primary-color: rgb(var(--v-theme-primary-darken2))
  --dp-menu-border-color: rgb(var(--v-theme-neutral-lighten1))
  --dp-range-between-dates-background-color: rgb(var(--v-theme-primary-lighten4))
  --dp-range-between-border-color: transparent

.datepicker .calendar-header-cell
  font-weight: 400
  color: rgb(var(--v-theme-neutral-darken1))

.datepicker :deep(.dp__calendar_header_separator)
  display: none

.datepicker :deep(.dp__btn), .datepicker :deep(.dp__overlay_cell)
  font-weight: 500

.datepicker :deep(.dp__instance_calendar ~ .dp__instance_calendar .dp--header-wrap)
  padding-inline-start: var(--dp-multi-calendars-spacing)

.datepicker :deep(.dp__calendar_item), .datepicker :deep(.dp__calendar_header_item)
  flex-grow: 0

.datepicker .action-container
  display: flex
  flex-direction: column

  width: 100%

.datepicker .action-row
  display: flex
  justify-content: right

  width: 100%
  padding-top: 12px
  padding-right: 32px
  padding-bottom: 12px
  padding-left: 32px
  border-bottom-right-radius: 4px
  border-bottom-left-radius: 4px

  background-color: rgb(var(--v-theme-neutral-lighten3))
</style>
