<script setup lang="ts">
import AssetBaseCard, { type AssetBaseCardProps } from './AssetBaseCard.vue'
import { Chart } from 'highcharts-vue'
import Highcharts from 'highcharts'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { VUETIFY_COLORS } from '@theme/colors'
import { TYPOGRAPHY } from '@/utils/designConstants'
import { formatValue } from '@/filters/formatting'

interface Props extends AssetBaseCardProps {
  series: Highcharts.SeriesLineOptions[]
  monthUnit?: string
}

const props = defineProps<Props>()

const { t } = useI18n()

Highcharts.setOptions({
  accessibility: {
    enabled: false,
  },
  chart: {
    style: {
      fontFamily: "'Inter', sans-serif",
    },
  },
  lang: {
    loading: t('highcharts.loading'),
    noData: t('highcharts.no_data'),
    shortWeekdays: [
      t('dates.short_weekdays.sunday'),
      t('dates.short_weekdays.monday'),
      t('dates.short_weekdays.tuesday'),
      t('dates.short_weekdays.wednesday'),
      t('dates.short_weekdays.thursday'),
      t('dates.short_weekdays.friday'),
      t('dates.short_weekdays.saturday')],
  },
  noData: {
    style: {
      color: VUETIFY_COLORS.neutral.darken2,
      fontSize: TYPOGRAPHY.headings.body1.size,
      fontWeight: TYPOGRAPHY.headings.body1.weight,
    },

  },
  time: {
    timezoneOffset: new Date().getTimezoneOffset(),
  },
})

const isDataEmpty = computed(() => {
  return (props?.series)?.every((serie) => serie?.data?.every((point) => point === null)) || props.series === undefined || props.value === ''
})

function preventClickPropagation (event: MouseEvent) {
  event.stopPropagation()
  event.preventDefault()
  return false
}

const chartOptions = computed<Highcharts.Options>(() => {
  const options: Highcharts.Options = {
    chart: {
      type: 'line',
      height: 188,
      marginRight: 53,
      marginBottom: 70,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: undefined,
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
    },
    tooltip: {
      formatter: function () {
        type Point = Highcharts.Point & { custom: { month: string } }

        const color = this.series.color
        const month = (this.point as Point).custom.month
        return `<div class="chart-base-style chart-tooltip" style="background-color: ${color};">
        ${month}: ${formatValue(this.y)}</div>`
      },
      shape: 'rect',
      useHTML: true,
      shadow: false,
      backgroundColor: 'transparent',
    },
    plotOptions: {
      series: {
        connectNulls: true,
        states: {
          hover: {
            lineWidth: 4,
          },
          inactive: {
            opacity: 1,
          },
        },
        events: {
          mouseOver: function () {
            this.chart.series.forEach(serie => {
              if (serie !== this) {
                serie.update({
                  type: 'line',
                  dataLabels: {
                    zIndex: 10,
                    enabled: false,
                  },
                }, true)
              }
            })
          },
          mouseOut: function () {
            this.chart.series.forEach(serie => {
              serie.update({
                dashStyle: 'Solid',
                type: 'line',
                dataLabels: {
                  enabled: true,
                  zIndex: 1,
                },
              }, true)
            })
          },
        },
        marker: {
          enabled: false,
        },
        dataLabels: {
          enabled: true,
          useHTML: true,
          formatter: function () {
            const series = this.series
            const lastNonNullPoint = series.data.slice().reverse().find(point => point.y !== null)

            if (this.point === lastNonNullPoint) {
              const customColorClass = this.series.userOptions.custom!.textColor!
              return `<div class="chart-label chart-base-style"
              style="color: ${customColorClass}
              ">${formatValue(this.y)}</div>`
            }
            return null
          },
          allowOverlap: true,
          inside: true,
          position: 'left',
          verticalAlign: 'middle',
          align: 'left',
          overflow: 'allow',
          crop: false,
        },
      },
    },
    legend: {
      y: 10,
      align: 'left',
      floating: true,
      verticalAlign: 'bottom',
      alignColumns: false,
      layout: 'vertical',
      borderWidth: 0,
      backgroundColor: '#FFFFFF',
      itemStyle: {
        fontSize: '12px',
      },
      shadow: false,
    },
    series: props.series,
  }

  // Hide legend and chart if all data points are null
  if (isDataEmpty.value) {
    options.legend = {
      enabled: false,
    }
    options.chart!.height = 1
  }

  return options
})

// #region PREVENT CLICK PROPAGATION ON CHART
const chartWrapper = ref<HTMLDivElement | null>(null)

onMounted(() => {
  if (chartWrapper.value) {
    chartWrapper.value.addEventListener('click', preventClickPropagation)
  }
})

onBeforeUnmount(() => {
  if (chartWrapper.value) {
    chartWrapper.value.removeEventListener('click', preventClickPropagation)
  }
})
// #endregion

</script>

<template>
  <AssetBaseCard
    v-bind="props"
  >
    <template
      v-if="!isDataEmpty"
      #default
    >
      <v-divider
        v-if="!isDataEmpty"
        class="border-opacity-100 tw-my-6 -tw-mx-6"
      />
      <div
        v-if="!isDataEmpty"
        class="tw-flex tw-justify-between tw-mt-6 text-body-1 text-neutral-darken1"
      >
        <span data-testid="asset-energy-card-period">{{ t('dates.monthly') }}</span>
        <span data-testid="asset-energy-card-unit">{{ props.monthUnit }}</span>
      </div>
      <div
        ref="chartWrapper"
      >
        <Chart
          data-testid="asset-energy-card-chart"
          class="tw-mt-6"
          :options="chartOptions"
        />
      </div>
    </template>
  </AssetBaseCard>
</template>

<style lang="sass" scoped>
:deep(.chart-base-style)
  font-size: 14px
  line-height: 20px
  font-weight: 600
  border-radius: 4px
  padding: 2px 4px
:deep(.chart-tooltip)
  color: #ffffff
:deep(.chart-label)
  background-color: rgb(var(--v-theme-neutral-lighten3))
  border: 1px solid rgb(var(--v-theme-neutral-lighten1))
</style>
