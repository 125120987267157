import { Alert, AlertsResponse, SEVERITY, STATUS } from '@/services/alerta/types'
import { ActionTree } from 'vuex'
import { Alerts } from '@/services/alerta'
import { AlertsState } from './types'
import i18n from '@/i18n'
import { IncidentConfiguration } from '@/vuex/status_and_alerts/types'
import { ProjectValue } from '@/utils/types'
import { ProjectWithContext } from '@aedifion.io/aedifion-api'
import { reportError } from '@/utils/helpers/errors'
import { resetStoreState } from './state'
import { RootState } from '../types'
import { showErrorNotification } from '@/utils/helpers/notifications'

/**
 * Takes a list of incident configurations and creates a list of pairs of their categories.
 *
 * Note: This makes use of slice checking against the length of the array. Ie., if the list has an uneven number of elements,
 * the last item in the result only contains one category.
 *
 * @param incidentConfigurations The incident configurations to convert.
 * @returns A list of pairs of incident configuration categories.
 */
function toCategoryPairs (incidentConfigurations: IncidentConfiguration[]): Array<string[]> {
  const incidentCategories: string[] = incidentConfigurations.map(item => item.category)
  const result: Array<string[]> = []
  for (let i = 0; i < incidentCategories.length; i += 2) {
    result.push(incidentCategories.slice(i, i + 2))
  }
  return result
}

export default {
  clear: ({ state }) => {
    resetStoreState(state)
  },

  fetchIncidentAlertsForProject: async ({ commit, rootGetters }, projectId: number) => {
    const project = rootGetters['projects/project'](projectId) as ProjectWithContext|null
    if (
      !project ||
      !project.project ||
      !rootGetters['building_analyses/isBuildingComponentOfProjectLoaded'](projectId) ||
      rootGetters['building_analyses/projectUsesSampleData'](projectId)
    ) {
      return
    }

    const incidentConfigurations = rootGetters['building_analyses/incidentConfigurationsOfProject'](projectId) as IncidentConfiguration[]

    if (!Array.isArray(incidentConfigurations) || incidentConfigurations.length === 0) {
      return
    }

    const incidentCategoryPairs = toCategoryPairs(incidentConfigurations)

    commit('SET_LOADING_PROJECT_INCIDENT_ALERTS', { projectId, value: true } as ProjectValue<boolean>)
    try {
      const alertsResponses: AlertsResponse[] = await Promise.all(
        incidentCategoryPairs.map((categoryPair: string[]) => {
          return Alerts.getAlerts(
            1,
            [SEVERITY.CRITICAL, SEVERITY.MAJOR],
            [STATUS.OPEN, STATUS.ACK],
            undefined,
            { key: 'project_handle', value: project.project!.handle! },
            categoryPair.length === 2 ? `attributes.category:(/${categoryPair[0]}/ OR /${categoryPair[1]}/)` : `attributes.category:(/${categoryPair[0]}/)`,
          )
        }),
      )
      commit('CLEAR_PROJECT_DATA', projectId)
      for (const alertsResponse of alertsResponses) {
        commit('ADD_PROJECT_INCIDENT_ALERTS', { projectId, value: alertsResponse.alerts } as ProjectValue<Alert[]>)
        commit('ADD_PROJECT_STATUS_COUNTS', { projectId, value: alertsResponse.statusCounts } as ProjectValue<Record<STATUS, number>>)
      }
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.alerts') })}`)
      reportError(error)
    } finally {
      commit('SET_LOADING_PROJECT_INCIDENT_ALERTS', { projectId, value: false } as ProjectValue<boolean>)
    }
  },
} as ActionTree<AlertsState, RootState>
