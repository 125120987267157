/* eslint-disable camelcase */
import { aedifionApi, AnalysisResult, AnalysisResultSummaries, InstanceConfig, Result, User } from '@aedifion.io/aedifion-api'
import { AnalysisInstancesState, ExtendedAnalysisResult, FetchAnalysisResultPayload, ToggleInstancePayload } from './types'
import { showErrorNotification, showSuccessNotification } from '@/utils/helpers/notifications'
import { ActionTree } from 'vuex'
import { getInstanceResultSampleData } from './sampleData'
import i18n from '@/i18n'
import { reportError } from '@/utils/helpers/errors'
import { resetStoreState } from './state'
import { RootState } from '../types'
import { useUserStore } from '@/stores/user'
import { validateNotNullish } from '@/utils/helpers/validate'

export default {
  clear: ({ state }): void => {
    resetStoreState(state)
  },

  fetchAnalysisResult: async ({ commit, rootGetters }, payload: FetchAnalysisResultPayload): Promise<void> => {
    commit('SET_LOADING_ANALYSIS_RESULT', true)
    const { instanceId, language, resultId } = payload
    const projectId = validateNotNullish(
      rootGetters['projects/currentProjectId'] as number|null,
      { errorMessage: i18n.global.t('notifications.errors.no_project_selected') as string },
    )

    try {
      if (resultId.includes('fake_result')) {
        const analysisResultApiResponse = getInstanceResultSampleData(payload.instanceId, language)!
        const customMutationData: ExtendedAnalysisResult = Object.assign(analysisResultApiResponse, {
          instance_id: instanceId,
          result_id: resultId,
        })
        commit('SET_ANALYSIS_RESULT', customMutationData)
      } else {
        const userStore = useUserStore()
        const user: Readonly<User> = validateNotNullish(userStore.userDetails)
        const analysisResultApiResponse: AnalysisResult = await aedifionApi.Analytics.getInstanceResult(
          projectId,
          instanceId,
          resultId,
          language,
          user.units_system,
          user.currency_system,
        )
        const customMutationData: ExtendedAnalysisResult = Object.assign(analysisResultApiResponse, {
          instance_id: instanceId,
          result_id: resultId,
        })
        commit('SET_ANALYSIS_RESULT', customMutationData)
      }
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.analysis_result') })}`)
      reportError(error)
    } finally {
      commit('SET_LOADING_ANALYSIS_RESULT', false)
    }
  },

  fetchAnalysisResults: async ({ commit, rootGetters }, instanceId: number): Promise<void> => {
    commit('SET_LOADING_ANALYSIS_RESULTS', true)
    const projectId = validateNotNullish(
      rootGetters['projects/currentProjectId'] as number|null,
      { errorMessage: i18n.global.t('notifications.errors.no_project_selected') as string },
    )

    try {
      const analysisResultsApiResponse: AnalysisResultSummaries[] = await aedifionApi.Analytics.getInstanceResults(projectId, instanceId)
      commit('SET_ANALYSIS_RESULTS', analysisResultsApiResponse)
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.analysis_results') })}`)
      reportError(error)
    } finally {
      commit('SET_LOADING_ANALYSIS_RESULTS', false)
    }
  },

  fetchInstances: async ({ commit, rootGetters }) => {
    commit('SET_LOADING_INSTANCES', true)
    const projectId = validateNotNullish(
      rootGetters['projects/currentProjectId'] as number|null,
      { errorMessage: i18n.global.t('notifications.errors.no_project_selected') as string },
    )

    try {
      const instancesApiResponse: InstanceConfig[] = await aedifionApi.Analytics.getAnalysisInstances(projectId)
      commit('SET_INSTANCES', instancesApiResponse)
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.analysis_instances.fetch')}`)
      reportError(error)
    } finally {
      commit('SET_LOADING_INSTANCES', false)
    }
  },

  toggleInstance: async ({ commit, getters, rootGetters }, instancePayload: ToggleInstancePayload): Promise<void> => {
    const {
      componentInProjectId,
      componentInProjectName,
      functionId,
      functionName,
      instanceId,
    } = instancePayload
    const instance: InstanceConfig|null = (getters.getInstances as InstanceConfig[]).find((instanceInList) => instanceInList.id === instanceId) ?? null
    commit('ADD_PENDING_INSTANCE_UPDATE_FOR_FUNCTION', functionId)

    try {
      const projectId = validateNotNullish(
        rootGetters['projects/currentProjectId'] as number|null,
        { errorMessage: i18n.global.t('notifications.errors.no_project_selected') as string },
      )

      let createInstanceApiResponse: Result<InstanceConfig>
      if (instanceId && instance) {
        createInstanceApiResponse = await aedifionApi.Analytics.putAnalysisInstance({
          enabled: !(instance.config.enabled ?? true),
        }, projectId, instanceId) as Result<InstanceConfig>
      } else {
        createInstanceApiResponse = await aedifionApi.Analytics.postAnalysisInstance({
          analysisfunction_id: functionId,
          componentinproject_id: componentInProjectId,
          description: 'frontend-generated',
          enabled: true,
          name: `${functionName} for ${componentInProjectName}`,
        }, projectId) as Result<InstanceConfig>
      }

      commit('UPDATE_INSTANCE', createInstanceApiResponse.resource)

      if (instance && instance.config.enabled) {
        showSuccessNotification(`${i18n.global.t('notifications.success.functions.toggle_instance', { action: i18n.global.t('actions.deactivated'), analysisFunction: functionName })}`)
      } else {
        showSuccessNotification(`${i18n.global.t('notifications.success.functions.toggle_instance', { action: i18n.global.t('actions.activated'), analysisFunction: functionName })}`)
      }
    } catch (error) {
      let errorMessage: string|null = null
      if (instance && instance.config.enabled) {
        errorMessage = `${i18n.global.t('notifications.errors.functions.toggle_instance', { action: i18n.global.t('actions.deactivated') })}`
      } else {
        errorMessage = `${i18n.global.t('notifications.errors.functions.toggle_instance', { action: i18n.global.t('actions.activated') })}`
      }
      showErrorNotification(errorMessage)
      reportError(error)
    } finally {
      commit('REMOVE_PENDING_INSTANCE_UPDATE_FOR_FUNCTION', functionId)
    }
  },
} as ActionTree<AnalysisInstancesState, RootState>
