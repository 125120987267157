<!-- eslint-disable no-irregular-whitespace -->
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import moment from 'moment'
import { reportError } from '@/utils/helpers/errors'
import type { AnalysisResult, AnalysisResultSummaries } from '@aedifion.io/aedifion-api'
import type { FetchAnalysisResultPayload } from '@/vuex/analysis_instances/types'
import { formatDateRange } from '@/utils/helpers/dates'
import useAnalysisResultHistoryItems from './useAnalysisResultHistoryItems'

interface Props {
  isReadOnly: boolean
  latestResultId: string|null
}

const { t, locale } = useI18n()
const vuexStore = useStore()

const props = withDefaults(defineProps<Props>(), {
  isReadOnly: false,
  latestResultId: null,
})

const emits = defineEmits<{
  'add-task': []
}>()

const analysisResult = computed<AnalysisResult|null>(() => {
  return vuexStore.state.analysis_instances.analysisResult
})

const analysisResultId = computed<string|null>(() => {
  return vuexStore.state.analysis_instances.analysisResult?.result_id ?? null
})

const currentResultYear = computed<number|null>(() => {
  if (analysisResult.value === null) {
    return null
  } else {
    return moment(analysisResult.value.start).year()
  }
})

const {
  analysisResultItems,
  analysisResultDropdownItems,
  hasNewerResults,
  hasOlderResults,
  selectedYear,
} = useAnalysisResultHistoryItems(currentResultYear.value ?? undefined)

const dropdownItems = computed(() => {
  return analysisResultDropdownItems.value
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .map((item: any) => {
      if (item.type === 'subheader') {
        return {
          title: moment(item.title).format('MMMM'),
          type: item.type,
        }
      } else {
        const isMonthly = moment(item.input_parameters.end).diff(moment(item.input_parameters.start), 'days') > 7
        return {
          title: isMonthly ? moment(item.input_parameters.start).format('MMMM') : formatDateRange(item.input_parameters.start, item.input_parameters.end),
          value: item,
        }
      }
    })
})

const isLatestResult = computed<boolean>(() => {
  return selectedResult.value?.result_id === props.latestResultId
})

const loadingResults = computed<boolean>(() => {
  return vuexStore.state.analysis_instances.loadingAnalysisResults
})

const selectedResult = computed<AnalysisResultSummaries|null>(() => {
  return vuexStore.state.analysis_instances.analysisResults?.find((analysisResultItem: AnalysisResultSummaries) => {
    return analysisResultItem.result_id === analysisResultId.value
  }) ?? null
})

async function switchResult (newResult: AnalysisResultSummaries): Promise<void> {
  const payload: FetchAnalysisResultPayload = {
    instanceId: analysisResult.value!.instance_id!,
    language: locale.value,
    resultId: newResult.result_id!,
  }
  try {
    await vuexStore.dispatch('analysis_instances/fetchAnalysisResult', payload)
  } catch (error) {
    reportError(error)
  }
}

function getAnalysisResultIcon (color: string|null): string|null {
  switch (color) {
    case 'green':
      return 'fa:fas fa-check-circle'
    case 'red':
      return 'fa:fas fa-diamond-exclamation'
    case 'yellow':
      return 'fa:fas fa-octagon-exclamation'
    default:
      return null
  }
}

function getAnalysisResultIconColor (color: string|null): string|null {
  switch (color) {
    case 'green':
      return 'success'
    case 'red':
      return 'error'
    case 'yellow':
      return 'warning'
    default:
      return null
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isMonthItem (item: any): boolean {
  return item.raw.type === 'subheader'
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getSelectionText (item: any) {
  const isMonthlyResult = moment(item.value.input_parameters.end).diff(moment(item.value.input_parameters.start), 'days') > 7
  if (isMonthlyResult) {
    return moment(item.value.input_parameters.start).format('MMMM')
  } else {
    return formatDateRange(item.value.input_parameters.start, item.value.input_parameters.end)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isSelectedItem (item: any) {
  return item.value.result_id === analysisResultId.value
}

const hasPreviousResult = computed(() => {
  return analysisResultItems.value.findIndex((item) => item.result_id === analysisResultId.value) < analysisResultItems.value.length - 1 || hasOlderResults.value
})

const hasNextResult = computed(() => {
  return analysisResultItems.value.findIndex((item) => item.result_id === analysisResultId.value) >= 1 || hasNewerResults.value
})

function selectPreviousResult (): void {
  const indexOfCurrentResult = analysisResultItems.value.findIndex((item) => item.result_id === analysisResultId.value)

  if (indexOfCurrentResult >= analysisResultItems.value.length - 1) {
    selectedYear.value = selectedYear.value - 1
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((analysisResultItems.value[0] as any).type) {
      switchResult(analysisResultItems.value[1])
    } else {
      switchResult(analysisResultItems.value[0])
    }
  } else {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((analysisResultItems.value[indexOfCurrentResult + 1] as any).type) {
      switchResult(analysisResultItems.value[indexOfCurrentResult + 2])
    } else {
      switchResult(analysisResultItems.value[indexOfCurrentResult + 1])
    }
  }
}

function selectNextResult (): void {
  const indexOfCurrentResult = analysisResultItems.value.findIndex((item) => item.result_id === analysisResultId.value)

  if (indexOfCurrentResult === 0) {
    selectedYear.value = selectedYear.value + 1
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((analysisResultItems.value[analysisResultItems.value.length - 1] as any).type) {
      switchResult(analysisResultItems.value[analysisResultItems.value.length - 2])
    } else {
      switchResult(analysisResultItems.value[analysisResultItems.value.length - 1])
    }
  } else {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((analysisResultItems.value[indexOfCurrentResult - 1] as any).type) {
      switchResult(analysisResultItems.value[indexOfCurrentResult - 2])
    } else {
      switchResult(analysisResultItems.value[indexOfCurrentResult - 1])
    }
  }
}
</script>

<template>
  <v-sheet
    class="mb-2 px-2 py-4 fill-height"
    rounded
  >
    <div class="d-flex align-center">
      <v-btn
        class="mr-2"
        data-cy="previousAnalysis"
        density="comfortable"
        :disabled="!hasPreviousResult"
        icon
        rounded
        @click="selectPreviousResult"
      >
        <v-icon
          size="14"
        >
          fa:fal fa-chevron-left
        </v-icon>
      </v-btn>
      <v-select
        class="mr-2 tw-w-[360px] tw-max-w-[360px] tw-min-w-[360px]"
        density="compact"
        hide-details
        :items="dropdownItems"
        :loading="loadingResults"
        variant="outlined"
        data-cy="historicalAnalysisResults"
        :list-props="{
          class: 'tw-p-0'
        }"
        :menu-props="{
          maxHeight: 400,
          minHeight: 400,
          height: 400,
        }"
        menu-icon=""
        :model-value="selectedResult"
        @update:model-value="switchResult"
      >
        <template #prepend-item>
          <div class="tw-flex tw-flex-row tw-justify-between tw-items-center tw-p-2 tw-sticky tw-top-0 tw-bg-white tw-z-50 tw-border-b">
            <v-btn
              class="pa-0"
              :disabled="!hasOlderResults"
              @click="selectedYear--"
            >
              <v-icon size="14px">
                fa:far fa-chevron-left
              </v-icon>
            </v-btn>
            <span class="tw-text-sm tw-font-semibold neutral--text text--darken3">{{ selectedYear }}</span>
            <v-btn
              class="pa-0"
              :disabled="!hasNewerResults"
              @click="selectedYear++"
            >
              <v-icon size="14px">
                fa:far fa-chevron-right
              </v-icon>
            </v-btn>
          </div>
        </template>
        <template #selection="{ item }">
          <span class="neutral--text text--darken1 tw-mr-[8px]">{{ t('historic_select_label') }}:</span> <span class="tw-mr-[8px]">{{ getSelectionText(item) }}</span> <v-icon size="14px">
            fa:fas fa-calendar-week
          </v-icon>
        </template>
        <template #item="{ item, props: selectItemProps }">
          <v-list-item
            v-bind="selectItemProps"
            :disabled="isMonthItem(item)"
            :title="undefined"
          >
            <template #prepend>
              <div
                v-if="!isMonthItem(item)"
                class="tw-w-[24px] tw-h-[24px] tw-flex tw-justify-center tw-items-center tw-mr-2"
              >
                <v-icon
                  v-if="isSelectedItem(item)"
                  class="tw-opacity-100"
                  size="14"
                >
                  fa:far fa-check
                </v-icon>
              </div>
            </template>
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
            <template #append>
              <v-icon
                size="16px"
                class="tw-opacity-100"
                :color="getAnalysisResultIconColor(item.value.signal_color)"
              >
                {{ getAnalysisResultIcon(item.value.signal_color) }}
              </v-icon>
            </template>
          </v-list-item>
        </template>
      </v-select>
      <v-btn
        class="mr-2"
        data-cy="nextAnalysis"
        density="comfortable"
        :disabled="!hasNextResult"
        icon
        rounded
        @click="selectNextResult"
      >
        <v-icon
          size="14"
        >
          fa:fal fa-chevron-right
        </v-icon>
      </v-btn>
      <v-btn
        v-if="!isReadOnly"
        color="primary-darken2"
        class="ml-auto"
        @click="emits('add-task')"
      >
        {{ t('add_task') }}
        <v-icon
          size="14"
          class="ml-3"
        >
          fa:far fa-circle-plus
        </v-icon>
      </v-btn>
    </div>
    <span
      v-if="!isLatestResult && !loadingResults"
      class="mt-2 mb-0 mx-11 d-flex justify-start align-items"
      data-cy="newerResultsAvailableWarning"
    >
      <v-icon
        class="mr-2"
        color="warning"
        size="small"
      >fa:fas fa-exclamation-triangle</v-icon>
      <span
        class="text-body-1 text-warning"
        v-text="t('old_result')"
      />
    </span>
  </v-sheet>
</template>

<i18n lang="json" locale="de">
  {
    "add_task": "Aufgabe hinzufügen",
    "historic_select_label": "Analysezeitraum",
    "old_result": "Es wird ein Ergebnis für einen älteren Zeitraum angezeigt. Ergebnisse für jüngere Zeiträume sind verfügbar."
  }
  </i18n>
  <i18n lang="json" locale="en">
  {
    "add_task": "Add task",
    "historic_select_label": "Analysis period",
    "old_result": "Showing results for older dates. Results for newer analysis dates are available."
  }
</i18n>
