import { computed, ref } from 'vue'
import { showErrorNotification, showSuccessNotification } from '@/utils/helpers/notifications'
import { defineStore } from 'pinia'
import i18n from '@/i18n'
import { reportError } from '@/utils/helpers/errors'
import { UpdateUser } from '@aedifion.io/aedifion-api'
import { useUserApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import { useUserStore } from '@/stores/user'

export const useUserProfileStore = defineStore('userProfile', () => {
  const userApiStore = useUserApiStore()
  const userStore = useUserStore()

  const isAvatarUpdatePending = ref(false)
  const isDetailsUpdatePending = ref(false)

  const avatar = computed<string | null>(() => {
    return userStore.user?.user?.avatar_url ?? null
  })

  async function deleteAvatar (): Promise<void> {
    isAvatarUpdatePending.value = true

    try {
      await userApiStore.deleteAvatar()
      if (userStore.user?.user?.avatar_url !== undefined) {
        userStore.user.user.avatar_url = undefined
      }
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.user.deleteAvatar')}`)
      reportError(error)
    } finally {
      isAvatarUpdatePending.value = false
    }
  }

  async function updateAvatar (avatar: File): Promise<void> {
    isAvatarUpdatePending.value = true

    try {
      const response = await userApiStore.postAvatar({ avatar })
      if (userStore.user?.user && response.success) {
        userStore.user.user.avatar_url = response.resource.avatar_url
      }
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.user.updateAvatar')}`)
      reportError(error)
    } finally {
      isAvatarUpdatePending.value = false
    }
  }

  async function updateDetails (user: UpdateUser): Promise<void> {
    isDetailsUpdatePending.value = true

    try {
      const response = await userApiStore.putUser({ user })
      if (userStore.user && response.success) {
        userStore.user.user = response.resource
      }
      showSuccessNotification(`${i18n.global.t('notifications.success.user.updateDetails')}`)
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.user.updateDetails')}`)
      reportError(error)
    } finally {
      isDetailsUpdatePending.value = false
    }
  }

  return {
    avatar,
    deleteAvatar,
    isAvatarUpdatePending,
    isDetailsUpdatePending,
    updateAvatar,
    updateDetails,
  }
})
