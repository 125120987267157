<script lang="ts">
import { computed, ref, useSlots, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import useInfiniteScroll from '@/composables/useInfiniteScroll'

export interface ItemDescriptor {
  [key: string]: string|number|undefined,
  id: string|number,
}
</script>

<script setup lang="ts" generic="Item extends ItemDescriptor">
// #region Declarations of props, emits, model, and slots
interface Props {
  dataTestId?: string,
  filterFields: (keyof Item)[],
  isEditMode: boolean,
  isReadOnly: boolean,
  itemLocaleKey: string,
  itemPool: Item[],
  loadMoreCallback?: () => Promise<void>,
  loading?: boolean,
  searchLabel?: string,
  selectedItem: Item|null,
  showClearButton?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  showClearButton: false,
})

const emits = defineEmits<{
  'deselect-item': [],
  'select-item': [Item]
}>()

const search = defineModel({
  default: '',
  type: String,
})

const slots = useSlots()

const { t, locale } = useI18n()
// #endregion

const selectedItemTitle = computed(() => {
  if (props.selectedItem) {
    return props.selectedItem.title
  }
  return ''
})

function onSelectItem (item: Item) {
  emits('select-item', item)
  isMenuOpen.value = false
}

const selectedItemLabel = computed(() => {
  if (props.isEditMode) return 'N/A'

  if (locale.value === 'en') {
    return t('select_item', { item: t(props.itemLocaleKey).toLowerCase() })
  }

  return t('select_item', { item: t(props.itemLocaleKey) })
})

const searchLabel = computed(() => {
  if (props.searchLabel !== undefined) {
    return props.searchLabel
  }

  if (locale.value === 'en') {
    return t('search', { item: t(props.itemLocaleKey).toLowerCase() })
  }

  return t('search', { item: t(props.itemLocaleKey) })
})

const filteredItems = computed(() => {
  if (search.value === '') return props.itemPool

  const searchTerm = search.value.toLowerCase()

  return props.itemPool.filter(item =>
    props.filterFields.some(filter =>
      item[filter] &&
      (item[filter] as string|number).toString().toLowerCase().includes(searchTerm),
    ),
  )
})

const isMenuOpen = ref(false)

function toggleMenu () {
  if (props.isReadOnly) return
  isMenuOpen.value = !isMenuOpen.value
}

watch(isMenuOpen, () => {
  search.value = ''
})

const infiniteScrollObserverTarget = ref<HTMLElement|null>(null)

if (props.loadMoreCallback) {
  useInfiniteScroll(
    infiniteScrollObserverTarget,
    props.loadMoreCallback,
    {
      rootMargin: '100px',
    },
  )
}

const getDataTestId = computed(() => (label: string) => {
  if (!props.dataTestId) {
    return
  }

  return `${props.dataTestId}-${label}`
})
</script>

<template>
  <div
    id="item-selector-with-filter"
  >
    <v-menu
      :model-value="isMenuOpen"
      :offset="[-42,0]"
      attach="#item-selector-with-filter"
      :close-on-content-click="false"
      :persistent="false"
      content-class="tw-w-[420px] tw-overflow-y-auto tw-bg-white aedifion-box-shadow tw-overflow-x-hidden"
      @update:model-value="toggleMenu"
    >
      <slot name="activator" />
      <template #activator="{ props: activatorProps }">
        <v-text-field
          v-if="!props.selectedItem && !selectedItemTitle"
          :model-value="selectedItemTitle"
          hide-details
          :data-testid="getDataTestId('empty-state')"
          single-line
          density="compact"
          :label="selectedItemLabel"
          readonly
          variant="plain"
          :class="['mt-0 pt-0 custom-input body-1-text',
                   { 'no-decoration': props.isEditMode, 'empty-state-label': !props.isEditMode, readonly: props.isReadOnly }
          ]"
          v-bind="activatorProps"
        />
        <div
          v-else
          v-bind="activatorProps"
          :data-testid="getDataTestId('selected')"
          class="d-flex flex-row tw-flex-1 align-center tw-w-full selected-user tw-h-[40px]"
          :class="{ readonly: props.isReadOnly }"
        >
          <slot
            v-if="slots['selected-item-icon']"
            name="selected-item-icon"
            :selected-item="props.selectedItem"
          />
          <div class="d-flex tw-justify-between tw-items-center tw-w-full">
            <div>
              <slot name="selected-item-title">
                <span
                  :data-testid="getDataTestId('name')"
                  class="text-body-1 tw-font-semibold tw-truncate"
                >{{ selectedItemTitle }}</span>
              </slot>
            </div>
            <v-btn
              v-if="props.showClearButton && !props.isReadOnly"
              class="ml-2"
              variant="text"
              size="24"
              :data-testid="getDataTestId('clear-button')"
              @click.stop="emits('deselect-item')"
            >
              <v-icon
                size="14"
                class="text-neutral-darken3"
              >
                far fa-xmark-circle
              </v-icon>
            </v-btn>
          </div>
        </div>
      </template>
      <v-text-field
        v-model="search"
        :data-testid="getDataTestId('search')"
        density="compact"
        single-line
        :label="searchLabel"
        hide-details
        variant="plain"
        class="tw-w-[408px] tw-h-[40px] search-input search-input-label body-1-text tw-overflow-hidden"
        @click="()=>{}"
      >
        <template
          v-if="slots['inner-search-icon']"
          #prepend-inner
        >
          <slot name="inner-search-icon" />
        </template>
      </v-text-field>
      <v-list
        max-height="290"
        width="420"
        color="white"
        density="compact"
        class="py-1"
      >
        <v-list-item
          v-for="item in filteredItems"
          :key="item.id"
          class="tw-cursor-pointer"
          @click="onSelectItem(item)"
        >
          <slot
            name="item-option"
            :item="item"
          />
        </v-list-item>
        <div
          v-if="props.loadMoreCallback"
          ref="infiniteScrollObserverTarget"
        />
        <v-progress-circular
          v-if="props.loading"
          size="24"
          indeterminate
          class="tw-block tw-mx-auto tw-my-2"
        />
      </v-list>
    </v-menu>
  </div>
</template>

<style lang="sass" scoped>
.search-input
  :deep(.v-input__control)
    height: 40px !important
    padding: 0 14px !important
  background-color: rgb(var(--v-theme-neutral-lighten5))
  border: 1px solid rgb(var(--v-theme-neutral-darken2))
  position: sticky
  width: 420px !important
  border-radius: 4px
  top: 0
  right: 0
  left: 0
  z-index: 1
  :deep(.v-label)
    padding: 0 8px !important
    color: rgb(var(--v-theme-neutral-darken2)) !important
    opacity: 1 !important
    font-weight: 600 !important

.custom-input
  margin-bottom: 1px !important
  :deep(.v-field__input, textarea):not(.v-textarea__sizer)
    height: fit-content
    padding: 8px !important
    z-index: 100 !important
  &:not(.v-textarea)
    :deep(.v-field__input)
      padding: 0 8px !important
      height: 40px !important
  :deep(.v-field)
    border: 1px solid transparent !important
    &:hover:not(.v-field--focused)
      background-color: rgb(var(--v-theme-neutral-lighten2)) !important
      border-radius: 4px !important
  :deep(.v-field--focused)
    border-color: rgb(var(--v-theme-neutral-darken2)) !important
    border-radius: 4px !important
    font-weight: 400 !important
  :deep(.v-label)
    text-decoration: underline !important
    padding: 0 8px !important
    color: rgb(var(--v-theme-neutral-darken3)) !important
    font-weight: 600 !important
  &.readonly
    :deep(.v-label)
      opacity: 1

.no-decoration
  :deep(.v-label)
    text-decoration: none !important

.body-1-text
  :deep(.v-field)
    input
      font-size: .875rem !important
      line-height: 1.25rem
    &:not(.v-field--focused)
      font-weight: 600 !important
.search-input-label
  :deep(.v-field)
    label
      padding: 1px 0px !important

:deep(.v-img__img--cover)
  border: 1px solid #A6A6A9
  border-radius: 24px

.selected-user
  :deep(.v-label)
    text-decoration: underline !important
  border: 1px solid transparent
  border-radius: 4px
  padding: 8px
  &:not(.readonly)
    &:hover
      border-color: rgb(var(--v-theme-neutral))
    &:focus
      border-color: rgb(var(--v-theme-neutral-darken2))
  &.readonly
    cursor: default !important

.empty-state-label
  :deep(.v-label)
    font-weight: 500 !important
    color: rgb(var(--v-theme-neutral-darken1)) !important
    opacity: 1

</style>

<i18n lang="json" locale="de">
  {
    "search": "{item} auswählen oder suchen",
    "select_item": "{item} auswählen"
  }
</i18n>
<i18n lang="json" locale="en">
  {
    "search": "Select or search {item}",
    "select_item": "Select {item}"
  }
</i18n>
