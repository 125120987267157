<script setup lang="ts">
import { Attribute } from '@/vuex/component_attributes_editor/types'
import ComponentHeader from '../ComponentHeader.vue'
import ComponentActions from '../ComponentActions.vue'
import { ComponentInProjectListItemData } from '@/vuex/components_in_project/types'
import { PropType, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { SelectedPeriod } from './ComponentAttributeScheduler.vue'
import StyledDatepicker from '@/components/StyledDatepicker.vue'

interface Props {
  attribute: Attribute
  componentInProject: ComponentInProjectListItemData
  selectedDays: Set<number>
  wasSelectedTimeSlotUpdated: boolean
}

const props = defineProps<Props>()

const dialogState = defineModel('dialogState', {
  default: false,
  required: true,
})

const selectedPeriod = defineModel('selectedPeriod', {
  type: Object as PropType<SelectedPeriod>,
  required: true,
  default: () => {
    return {
      daysId: [],
      timeSlots: [],
    }
  },
})

const emits = defineEmits<{
  cancel: []
  save: []
  toggleSelectedDay: [number]
  clearTimeSlot: [number]
  deleteTimeSlot: [number]
  addTimeSlot: []
}>()

const { t } = useI18n()

type ScheduleDay = {
  title: string
  id: number
  selected: boolean
}
const scheduleDays = computed<ScheduleDay[]>(() => {
  return [{
    title: t('dates.short_weekdays.monday'),
    id: 0,
    selected: props.selectedDays.has(0),
  }, {
    title: t('dates.short_weekdays.tuesday'),
    id: 1,
    selected: props.selectedDays.has(1),
  }, {
    title: t('dates.short_weekdays.wednesday'),
    id: 2,
    selected: props.selectedDays.has(2),
  }, {
    title: t('dates.short_weekdays.thursday'),
    id: 3,
    selected: props.selectedDays.has(3),
  }, {
    title: t('dates.short_weekdays.friday'),
    id: 4,
    selected: props.selectedDays.has(4),
  }, {
    title: t('dates.short_weekdays.saturday'),
    id: 5,
    selected: props.selectedDays.has(5),
  }, {
    title: t('dates.short_weekdays.sunday'),
    id: 6,
    selected: props.selectedDays.has(6),
  }]
})

function getScheduleDayIcon (day: ScheduleDay): string {
  return day.selected ? 'fa:far fa-check' : 'fa:far fa-add'
}

const isTimeSlotFilled = computed<boolean>(() => {
  return selectedPeriod.value.timeSlots.every((timeSlot) => timeSlot[0] && timeSlot[1])
})
</script>

<template>
  <v-dialog
    v-model="dialogState"
    persistent
    width="564"
    data-testid="schedule-editor"
  >
    <v-sheet>
      <ComponentHeader
        :title="componentInProject.title"
        :description="componentInProject.description"
      />
      <div class="tw-p-6 bg-neutral-lighten3 tw-flex tw-flex-col tw-font-semibold tw-h-[calc(100%-68px)]">
        <h5 class="tw-mb-6 text-h5">
          {{ t('schedule') }}
        </h5>
        <!-- DAYS SECTION -->
        <div class="tw-flex tw-flex-wrap tw-gap-2 tw-mb-6">
          <v-btn
            v-for="scheduleDay in scheduleDays"
            :key="scheduleDay.title"
            :data-testid="`schedule-day-${scheduleDay.id}`"
            :class="['tw-rounded-full tw-h-8 tw-px-[12px]', {
              'selected-day': scheduleDay.selected,
              'non-selected-day': !scheduleDay.selected,
            }]"
            :color="scheduleDay.selected ? 'neutral-darken4' : 'neutral-lighten1'"
            variant="outlined"
            @click="emits('toggleSelectedDay', scheduleDay.id)"
          >
            <v-icon
              :class="['tw-mr-2 text-neutral-darken1', { 'text-neutral-lighten5': scheduleDay.selected }]"
              size="14"
            >
              {{ getScheduleDayIcon(scheduleDay) }}
            </v-icon>
            <span
              :class="['text-neutral-darken4', { 'text-neutral-lighten5': scheduleDay.selected }]"
              v-text="scheduleDay.title"
            />
          </v-btn>
        </div>
        <!-- TIME SLOTS -->
        <div
          v-if="selectedPeriod.timeSlots.length"
          data-testid="time-slots-table"
        >
          <div class="tw-flex tw-w-full">
            <div class="tw-bg-transparent">
              <!-- Header -->
              <div class="tw-grid tw-grid-cols-5 tw-gap-2 tw-mb-4 text-legend text-neutral-darken1">
                <span>{{ t('time_periods') }}</span>
                <span>{{ t('start_time') }}</span>
                <span>{{ t('end_time') }}</span>
                <div />
              </div>
              <!-- Body -->
              <div
                v-for="(editedPeriod, index) in selectedPeriod.timeSlots"
                :key="`${editedPeriod}`"
                class="tw-grid tw-grid-cols-5 tw-gap-2 tw-auto-rows-[40px] tw-mb-2"
                :data-testid="`time-slot-${index + 1}`"
              >
                <!-- Rows -->
                <div class="tw-contents">
                  <span class="text-body-1 text-neutral-darken3 tw-block tw-my-auto">Slot {{ index + 1 }}</span>
                  <StyledDatepicker
                    v-model="editedPeriod[0]"
                    is-24
                    minutes-increment="15"
                    data-testid="start-time-input"
                    model-type="HH:mm"
                    time-picker
                    teleport-center
                    placeholder="--:--"
                  />
                  <StyledDatepicker
                    v-model="editedPeriod[1]"
                    is-24
                    minutes-increment="15"
                    data-testid="end-time-input"
                    model-type="HH:mm"
                    time-picker
                    teleport-center
                    :placeholder="'--:--'"
                  />
                  <v-btn
                    variant="text"
                    :disabled="!editedPeriod[0] && !editedPeriod[1]"
                    color="error-darken2"
                    :data-testid="`clear-time-slot-btn-${index}`"
                    @click="emits('clearTimeSlot', index)"
                  >
                    {{ t('actions.clear') }}
                  </v-btn>
                  <v-btn
                    variant="text"
                    :data-testid="`delete-time-slot-btn-${index}`"
                    color="error-darken2"
                    @click="emits('deleteTimeSlot', index)"
                  >
                    <v-icon size="16">
                      far fa-trash
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ADD TIME SLOT -->
        <v-btn
          v-if="!selectedPeriod.timeSlots.length"
          class="tw-bg-white tw-rounded-full tw-h-8 tw-px-[12px] tw-w-fit"
          color="neutral-lighten1"
          data-testid="add-time-slot-btn"
          variant="outlined"
          @click="emits('addTimeSlot')"
        >
          <v-icon
            class="tw-mr-2 text-neutral-darken1"
            size="14"
          >
            fa:far fa-plus
          </v-icon>
          <span class="text-neutral-darken4">{{ t('time_period') }}</span>
        </v-btn>
        <ComponentActions
          :disable-save="!props.wasSelectedTimeSlotUpdated || !isTimeSlotFilled"
          @cancel="emits('cancel')"
          @save="emits('save')"
        />
      </div>
    </v-sheet>
  </v-dialog>
</template>

<style lang="sass" scoped>
:deep(.dp__menu)
  width: 150px
  .dp__overlay
    height: 150px !important

:deep(.action-row)
  display: flex
  justify-content: center !important

:deep(.dp__input_wrap)
  button
   width: 80px !important
   padding: 0 !important
   & > i
    display: none !important
   & > span
    margin: 0 20px 0 0 !important

.selected-day
  background-color: rgb(var(--v-theme-neutral-darken4))
  color: rgb(var(--v-theme-neutral-lighten5))

.non-selected-day
  background-color: rgb(var(--v-theme-neutral-lighten5))
  color: rgb(var(--v-theme-neutral-darken4))
</style>

<i18n lang="json" locale="de">
  {
    "schedule": "Zeitplan",
    "time_periods": "Zeiträume",
    "time_period": "Zeitraum",
    "start_time": "Startzeit",
    "end_time": "Endzeit"
  }
</i18n>
<i18n lang="json" locale="en">
  {
    "schedule": "Schedule",
    "time_periods": "Time Periods",
    "time_period": "Time Period",
    "start_time": "Start Time",
    "end_time": "End Time"
  }
</i18n>
