<script setup lang="ts">
import { computed, ref, WritableComputedRef } from 'vue'
import { Meter, MeterAndObservation } from '@/vuex/asset_overview/types'
import { formatDate } from '@/filters/formatting'
import { Observation } from '@aedifion.io/aedifion-api'
import { useI18n } from 'vue-i18n'
import { useStore as useVuexStore } from '@/vuex'
import ComponentHeader from '@/views/Optimization/Components/ComponentAttributes/ComponentHeader.vue'

interface Props {
  meter?: Meter
  reading?: Observation
  description: string
  unit: string
  value?: boolean
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (event: 'reading-added'): void
  (event: 'reading-editor:close'): void
}>()

const { t } = useI18n()

const vuexStore = useVuexStore()

const editReading = ref<Observation>({
  time: new Date().toISOString(),
  value: 0,
})

if (props.reading) {
  editReading.value = { ...props.reading }
}

const showDatePicker = ref(false)

const date = computed(() => {
  if (editReading.value?.time) {
    return formatDate(editReading.value.time)
  } else {
    return ''
  }
})

const isoDate = computed({
  get (): Date|null {
    if (editReading.value?.time) {
      return new Date(editReading.value.time)
    } else {
      return null
    }
  },
  set (newValue) {
    editReading.value.time = newValue!.toISOString()
  },
})

const maxDate = new Date().toISOString().split('T')[0]

const timeseriesValue: WritableComputedRef<string|null> = computed({
  get (): string|null {
    if (editReading.value?.value) {
      return editReading.value.value.toString()
    } else {
      return null
    }
  },
  set (newValue) {
    editReading.value.value = Number(newValue)
  },
})

const updatePending = computed(() => {
  return vuexStore.state.asset_overview.pendingReadingUpdate
})

async function saveReading (): Promise<void> {
  await vuexStore.dispatch('asset_overview/createOrUpdateUserMeterReading', { meter: props.meter, observation: editReading.value } as MeterAndObservation)
  vuexStore.commit('asset_overview/ADD_ANALOG_METER_TO_EXECUTE_ANALYSES', props.meter)
  emit('reading-added')
  emit('reading-editor:close')
}

const wasValueUpdated = computed(() => {
  if (!editReading.value.value) {
    return false
  }

  return props.reading?.value !== editReading.value.value
})

</script>

<template>
  <v-dialog
    persistent
    :model-value="value"
    width="480"
    @keydown.escape="emit('reading-editor:close')"
  >
    <form
      autocomplete="off"
      @submit.prevent="saveReading"
    >
      <v-card
        class="tw-border-0 aedifion-box-shadow-no-border"
      >
        <ComponentHeader
          :title="meter?.meterName ?? ''"
          :description
        />
        <div class="tw-p-6 bg-neutral-lighten3 tw-font-semibold">
          <h5 class="tw-mb-6 text-h5">
            {{ reading ? t('edit_title') : t('add_title') }}
          </h5>
          <!-- INPUT FIELDS -->
          <div class="tw-gap-2 tw-flex tw-flex-row tw-flex-nowrap tw-mb-6">
            <div
              class="tw-p-0 tw-grow tw-w-[50%]"
              cols="6"
            >
              <v-menu
                v-model="showDatePicker"
                :close-on-content-click="false"
                min-width="auto"
                transition="scale-transition"
              >
                <template #activator="{ props: menuProps }">
                  <v-text-field
                    v-model="date"
                    :disabled="!!reading"
                    hide-details="auto"
                    :label="t('date')"
                    readonly
                    v-bind="menuProps"
                  />
                </template>
                <v-date-picker
                  v-model="isoDate"
                  color="primary-darken2"
                  width="322"
                  :max="maxDate"
                  @update:model-value="showDatePicker = false"
                />
              </v-menu>
            </div>
            <div
              class="tw-p-0 tw-grow tw-w-[50%]"
              cols="6"
            >
              <v-text-field
                v-model="timeseriesValue"
                data-cy="reading-editor-value"
                hide-details="auto"
                :label="t('value')"
                :suffix="unit"
              />
            </div>
          </div>
          <!-- CARD ACTIONS -->
          <div class="tw-gap-2 tw-inline-flex tw-w-full tw-max-w-full">
            <v-btn
              color="primary-lighten3"
              :disabled="updatePending"
              variant="elevated"
              class="tw-grow tw-basis-1/5 text-primary-darken2"
              @click="emit('reading-editor:close')"
            >
              {{ t('actions.cancel') }}
            </v-btn>
            <v-btn
              color="primary-darken2"
              variant="elevated"
              class="tw-grow tw-basis-1/5"
              data-cy="reading-editor-save-button"
              :loading="updatePending"
              type="submit"
              :disabled="!wasValueUpdated"
            >
              {{ t('actions.save') }}
            </v-btn>
          </div>
        </div>
      </v-card>
    </form>
  </v-dialog>
</template>

<style lang="sass" scoped>
:deep(.v-input:not(.v-input--is-focused) .v-text-field__suffix)
  color: rgb(var(--v-theme-secondary))

:deep(.v-field__field)
  background-color: #ffffff
</style>

<i18n lang="json" locale="de">
  {
    "add_title": "Zählerstand erstellen",
    "date": "Datum",
    "edit_title": "Zählerstand bearbeiten",
    "value": "Zählerstand"
  }
</i18n>
<i18n lang="json" locale="en">
  {
    "add_title": "Add meter reading",
    "date": "Date",
    "edit_title": "Edit meter reading",
    "value": "Meter reading"
  }
</i18n>
