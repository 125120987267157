import { computed, onBeforeUnmount, onMounted, watch } from 'vue'
import { useAIControlsLogAppsStore } from '@/stores/views/AIControls/Log/apps'
import { useAIControlsLogLogsStore } from '@/stores/views/AIControls/Log/logs'
import { useAppStore } from '@/stores/app'
import debounce from 'lodash.debounce'
import { useAIControlsAppStore } from '@/stores/views/AIControls/App/app'
import { useRoute, useRouter } from 'vue-router'

export function useSelectControlsApp () {
  const aiControlsAppsLogsStore = useAIControlsLogAppsStore()
  const aiControlsLogLogsStore = useAIControlsLogLogsStore()
  const aiControlsAppStore = useAIControlsAppStore()
  const appStore = useAppStore()
  const router = useRouter()
  const route = useRoute()

  const applySearchDelayed = debounce(function (value: string) {
    aiControlsAppsLogsStore.searchControlsApps(value)
  }, 500)

  function onControlsAppSelected (id: string) {
    aiControlsAppsLogsStore.selectControlsApp(id)

    if (isAppPage()) {
      aiControlsAppStore.fetchApp(id)
    }

    if (isLogsPage()) {
      aiControlsLogLogsStore.resetAndFetchLogs()
    }
  }

  onMounted(async () => {
    if (appStore.currentProjectControlsAppCount === null) {
      const projectHasControlsApps = await appStore.projectHasControlsApp(appStore.projectId)

      if (projectHasControlsApps) {
        await aiControlsAppsLogsStore.fetchMoreControlsApps()

        handleViewSpecificLogic(true)
      }
    } else if (appStore.currentProjectHasControlsApp) {
      // We should only fetch more apps if we scroll down the appSelector
      if (!aiControlsAppsLogsStore.state.apps) {
        await aiControlsAppsLogsStore.fetchMoreControlsApps()
      }

      handleViewSpecificLogic(true)
    }
  })

  watch(() => appStore.projectId, async (newProjectId) => {
    const projectHasControlsApps = await appStore.projectHasControlsApp(newProjectId)
    if (projectHasControlsApps) {
      await aiControlsAppsLogsStore.fetchMoreControlsApps()

      handleViewSpecificLogic()
    } else {
      router.push({
        name: 'ai-controls',
        params: { project: appStore.projectId },
      })
    }
  })

  // #region HELPER FUNCTIONS
  async function updateRouteWithSelectedAppId () {
    const appId = aiControlsAppsLogsStore.state.apps?.[0]?.id ?? ''
    await router.replace({ name: 'ai-controls-app-id', params: { appId } })
  }

  function isLogsPage () {
    return (route.name === 'ai-controls-log')
  }

  function isAppPage () {
    return (route.name as string).includes('ai-controls-app')
  }

  function selectFirstAppAndResetLogs () {
    if (!aiControlsAppsLogsStore.state.selectedControlsApp) {
      aiControlsAppsLogsStore.selectFirstControlsApp()
    }

    aiControlsLogLogsStore.resetAndFetchLogs()
  }

  async function setAppIdAndFetchApp (trySelectingAppFromRouteFirst = false) {
    // In case the appId was set from the ai-controls page or has been previously selected by the user, we keep it otherwise we select the first app
    if (trySelectingAppFromRouteFirst) {
      const appId = route.params.appId
      if (!appId) { await router.replace({ name: 'ai-controls-app-id', params: { appId: aiControlsAppsLogsStore.state.apps?.[0]?.id } }) }
    } else {
      // This only executes on project change, that means we should select the first app of the new project
      await updateRouteWithSelectedAppId()
    }
    const appId = (route.params.appId as string)
    aiControlsAppsLogsStore.selectControlsApp(appId)
    aiControlsAppStore.fetchApp(appId)
  }

  async function handleViewSpecificLogic (trySelectingAppFromRouteFirst = false) {
    if (isAppPage()) {
      await setAppIdAndFetchApp(trySelectingAppFromRouteFirst)
    } else if (isLogsPage()) {
      selectFirstAppAndResetLogs()
    }
  }
  // #endregion

  onBeforeUnmount(() => {
    // This is needed to reset the store after searching for an app from the ai_controls overview page and selecting it, otherwise the search will still be applied
    // And the results will be still limited to the search query
    if (aiControlsAppsLogsStore.state.search) {
      aiControlsAppsLogsStore.clear(false)
    }
  })

  return {
    apps: computed(() => aiControlsAppsLogsStore.state.apps),
    search: computed(() => aiControlsAppsLogsStore.state.search),
    fetchMoreControlsApps: aiControlsAppsLogsStore.fetchMoreControlsApps,
    debouncedSearchApps: applySearchDelayed,
    selectControlsApp: onControlsAppSelected,
  }
}
