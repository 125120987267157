<script setup lang="ts">
import { computed, onBeforeUnmount, ref } from 'vue'
import type { DeleteMeterReadingPayload, Meter, MeterReadings } from '@/vuex/asset_overview/types'
import { formatDate, formatNumber } from '@/filters/formatting'
import { type ActionMenuItem } from '@/components/ActionMenu.vue'
import CalculateKpisPendingInfo from '@/views/Meter/CalculateKpisPendingInfo.vue'
import ConfirmationDialog from '@/components/ConfirmationDialog.vue'
import ComponentHeader from '@/views/Optimization/Components/ComponentAttributes/ComponentHeader.vue'
import { docsLink, textForLocale } from '@/utils/helpers/locale'
import KebabMenu from '@/components/KebabMenu.vue'
import { Observation } from '@aedifion.io/aedifion-api'
import ReadingEditor from '@/views/Meter/ReadingEditor.vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

// #region INITIALIZATION OF PROPS, EMITS, AND COMPOSABLES
interface Props {
  loading?: boolean
  meter?: Meter
  meterAnalysisAvailable?: boolean
  readOnly?: boolean,
  readings: MeterReadings
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  meterAnalysisAvailable: false,
  readOnly: false,
})

defineEmits<{
  (e: 'close-dialog'): void
}>()

const store = useStore()
const { t } = useI18n()
// #endregion

// #region TABLE CONFIGURATION: HEADERS, EDIT MENU ITEMS, UNIT, AND DOCUMENTATION LINK
const headers = ref([{
  align: 'start',
  sortable: true,
  title: t('date'),
  value: 'time',
}, {
  align: 'end',
  sortable: true,
  title: t('reading'),
  value: 'value',
},
{
  align: 'end',
  sortable: true,
  title: '',
  value: 'actions',
},
])

const kebabMenuItems = ref<ActionMenuItem[]>([{
  icon: 'fa:fas fa-pen',
  id: 'edit',
  label: t('actions.edit'),
}, {
  icon: 'fa:fas fa-trash',
  id: 'delete',
  label: t('actions.delete'),
}])

const availableUnitLabelId = () => {
  switch (props.meter?.alphanumericId) {
    case 'CM': return 'kilowatt-hours'
    case 'ELM': return 'kilowatt-hours'
    case 'GASM': return 'cubic-meters'
    case 'HM': return 'kilowatt-hours'
    case 'WSM': return 'generic'
    default:
      throw Error(`Unknown meter type: '${props.meter?.alphanumericId}'`)
  }
}

const unit = computed<string|null>(() => {
  let unitLabelId: string|undefined = props.readings.unitLabelId
  if (!unitLabelId) {
    unitLabelId = availableUnitLabelId()
  }
  const unitLabel = store.getters['labels/label']('units', unitLabelId)
  return unitLabel ? unitLabel.symbol : null
})

const userMetersDocsLink = ref<string|null>(docsLink('userMeters'))
// #endregion

// #region AUTO-TRIGGERING OF METER ANALYSES
const hasAnalysisBeenTriggered = ref(false)

const hasReadingBeenAdded = ref(false)

const shouldAutomaticallyTriggerMeterAnalyses = computed<boolean>(() => hasReadingBeenAdded.value && !hasAnalysisBeenTriggered.value)

onBeforeUnmount(() => {
  // Automatically trigger meter analyses if a reading has been added and the user has not triggered the analyses yet
  if (shouldAutomaticallyTriggerMeterAnalyses.value) {
    store.dispatch('asset_overview/triggerUserMeterAnalysesForAllAnalogMeters',
      {
        analogMeters: store.state.asset_overview.analogMetersToExecuteAnalysis,
        projectId: store.state.projects.currentProjectId,
      })

    if (props.meterAnalysisAvailable) {
      store.dispatch('asset_overview/triggerUserMeterAnalyses')
    }
  }
})
// #endregion

// #region CRUD OPERATIONS ON METER READINGS
const readingToEdit = ref<Observation|null>(null)

const addReading = () => {
  readingToEdit.value = null
  showReadingEditor.value = true
}

const showDeleteReadingConfirmation = ref(false)
const deleteReading = () => {
  store.dispatch('asset_overview/deleteMeterReading', {
    end: new Date(readingToEdit.value?.time as string),
    readingsId: props.readings.dataPointId,
    start: new Date(readingToEdit.value?.time as string),
  } as DeleteMeterReadingPayload)

  showDeleteReadingConfirmation.value = false
}

const showReadingEditor = ref(false)
const editReading = (reading: Observation) => {
  readingToEdit.value = reading
  showReadingEditor.value = true
}

const onDeleteReadingRequested = (reading: Observation) => {
  readingToEdit.value = reading
  showDeleteReadingConfirmation.value = true
}
// #endregion

// #region TRIGGER_ANALYSES FUNCTIONALITY AND BUTTON
const showCalculateKpisPendingInfo = ref(false)

const isCalculateKPIButtonDisabled = computed<boolean>(() => {
  return props.loading || !hasReadingBeenAdded.value
})

const calculateKpis = () => {
  hasReadingBeenAdded.value = false
  hasAnalysisBeenTriggered.value = true

  store.dispatch('asset_overview/triggerUserMeterAnalysesForAllAnalogMeters',
    {
      analogMeters: store.state.asset_overview.analogMetersToExecuteAnalysis,
      projectId: store.state.projects.currentProjectId,
    })

  if (props.meterAnalysisAvailable) {
    store.dispatch('asset_overview/triggerUserMeterAnalyses')
  }

  showCalculateKpisPendingInfo.value = true
}
// #endregion

const meterDescription = computed(() => {
  const meterType = textForLocale(props.meter?.componentNameDe ?? '', props.meter?.componentName ?? '')
  const meterNumber = props.meter?.number ?? ''

  return meterType + (meterNumber ? ` · ${meterNumber}` : '')
})

/**
 * * This ref is exposed, because it is being used in the ReadingsTable.spec.ts
 */
defineExpose({
  hasReadingBeenAdded,
})
</script>

<template>
  <v-card
    class="tw-m-auto tw-border-0 aedifion-box-shadow-no-border"
    width="480"
  >
    <ComponentHeader
      :title="meter?.meterName ?? ''"
      :description="meterDescription"
    >
      <template #default>
        <v-btn
          class="tw-ml-auto"
          data-testid="dialog-close-button"
          size="24"
          variant="text"
        >
          <v-icon
            size="14"
            @click="$emit('close-dialog')"
          >
            fa:far fa-xmark-large
          </v-icon>
        </v-btn>
      </template>
    </ComponentHeader>
    <div class="tw-p-6 bg-neutral-lighten3 tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden">
      <v-card-title class="text-h5 bg-neutral-lighten3 tw-p-0 tw-flex">
        <span>{{ t('title') }}</span>
        <v-btn
          class="tw-ml-auto"
          :href="userMetersDocsLink"
          icon
          color="neutral-lighten3"
          rel="noopener noreferrer"
          target="_blank"
          size="x-small"
        >
          <v-icon
            color="neutral-darken3"
            size="14"
          >
            fa:far fa-info-circle
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="tw-p-0 tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden">
        <span
          class="tw-mb-6 tw-block text-neutral-darken2"
          v-text="t('two_months_hint')"
        />
        <v-data-table
          data-cy="readings-table"
          :headers="headers"
          hide-default-footer
          :items="readings.readings"
          :items-per-page="-1"
          fixed-footer
          sort-asc-icon="fa:fas fa-sort-up"
          sort-desc-icon="fa:fas fa-sort-down"
          :loading="loading"
          :sort-by="[
            {
              key: 'time',
              order: 'desc'
            }
          ]"
          class="tw-overflow-auto"
        >
          <template #item.time="{ item }">
            {{ formatDate(item.time) }}
          </template>
          <template #item.value="{ item }">
            {{ formatNumber(item.value, { unit }) }}
          </template>
          <template #item.actions="{ item }">
            <KebabMenu
              v-if="!props.readOnly"
              small
              :custom-event-data="item"
              :items="kebabMenuItems"
              height="30"
              width="30"
              @edit-option-click="editReading"
              @delete-option-click="onDeleteReadingRequested"
            />
          </template>
          <template #bottom>
            <v-divider
              v-if="!props.readOnly"
              class="border-opacity-100"
            />
            <v-btn
              v-if="!props.readOnly"
              class="tw-my-2 tw-self-start"
              color="primary-darken2"
              data-cy="add-reading-button"
              variant="text"
              @click="addReading"
            >
              <span class="tw-mr-3 text-cta">{{ t('add_meter_reading') }}</span>
              <v-icon
                size="14"
              >
                fa:far fa-plus-circle
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <!-- CARD ACTIONS -->
      <div class="tw-pt-6">
        <v-btn
          color="primary-darken2"
          class="tw-w-full"
          variant="elevated"
          :disabled="isCalculateKPIButtonDisabled"
          data-testid="calculate-kpis-button"
          @click="calculateKpis"
        >
          {{ t('calculate_kpis') }}
          <v-icon
            size="14"
            class="tw-ml-3"
          >
            fa:far fa-rotate-right
          </v-icon>
        </v-btn>
      </div>
    </div>
    <ReadingEditor
      v-if="showReadingEditor"
      :meter="meter"
      :description="meterDescription"
      :reading="readingToEdit"
      :unit="unit"
      :value="showReadingEditor"
      @reading-editor:close="showReadingEditor = false"
      @reading-added="hasReadingBeenAdded = true"
    />
    <ConfirmationDialog
      v-if="showDeleteReadingConfirmation"
      v-model="showDeleteReadingConfirmation"
      accept-button-text-key="actions.delete"
      :title="t('delete_reading_confirmation_title')"
      @confirmation-dialog:accept="deleteReading"
      @confirmation-dialog:cancel="showDeleteReadingConfirmation = false"
    >
      {{ t('delete_reading_confirmation_text') }}
    </ConfirmationDialog>
    <CalculateKpisPendingInfo
      v-if="showCalculateKpisPendingInfo"
      :value="showCalculateKpisPendingInfo"
      @calculate-kpis-info:close="showCalculateKpisPendingInfo = false"
    />
  </v-card>
</template>

<style lang="sass" scoped>
:deep(.v-table)
  --v-table-row-height: 40px !important
  --v-table-header-height: 40px !important
:deep(.v-table__wrapper)
  min-height: 200px
</style>

<i18n lang="json" locale="de">
  {
    "add_meter_reading": "Zählerstand hinzufügen",
    "calculate_kpis": "KPIs berechnen",
    "date": "Datum",
    "delete_reading_confirmation_text": "Soll der Zählerstand wirklich gelöscht werden?",
    "delete_reading_confirmation_title": "Zählerstand löschen",
    "reading": "Zählerstand",
    "title": "Zählerstände",
    "two_months_hint": "Um Zähler-KPIs zu berechnen werden Zählerstände von mindestens zwei Monaten benötigt."
  }
</i18n>
<i18n lang="json" locale="en">
  {
    "add_meter_reading": "Add meter reading",
    "calculate_kpis": "Calculate KPIs",
    "date": "Date",
    "delete_reading_confirmation_text": "Should the reading really be deleted?",
    "delete_reading_confirmation_title": "Delete reading",
    "reading": "Meter reading",
    "title": "Meter Readings",
    "two_months_hint": "To calculate KPIs, two months of meter readings are required."
  }
</i18n>
