import texts from '@theme/texts'
import { useUserStore } from '@/stores/user'
import vuexStore from '@/vuex'
import { computed } from 'vue'

export default function useIsStrabagDemoUser () {
  const userStore = useUserStore()
  const projectId = computed<number|undefined>(() => vuexStore.getters['projects/currentProjectId'])

  const hasUserDemoRole = userStore.projectRoles.some(role => role.name === 'demo' && role.project_id === projectId.value)
  const isThemeStrabag = texts.appName === 'STRABAG'

  return isThemeStrabag && hasUserDemoRole
}
