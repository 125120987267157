import { computed } from 'vue'
import { useDisplay } from 'vuetify/lib/framework.mjs'

export default function useDefaultNumberOfColumns () {
  const display = useDisplay()

  const defaultNumberOfColumns = computed(() => {
    if (display.smAndDown.value) return 1
    if (display.md.value) return 2
    if (display.lg.value) return 3
    return 4
  })

  return {
    defaultNumberOfColumns,
  }
}
