<script setup lang="ts">
import { useI18n } from 'vue-i18n'

interface Props {
  disableSave: boolean
}

withDefaults(defineProps<Props>(), {
  disableSave: false,
})

defineEmits<{
  'save': [],
  'cancel': []
}>()

const { t } = useI18n()
</script>

<template>
  <div class="tw-p-0 tw-mt-6 tw-flex tw-gap-2">
    <v-btn
      class="tw-bg-white tw-grow"
      color="primary-darken2"
      data-testid="cancel-button"
      variant="outlined"
      @click="$emit('cancel')"
    >
      <span class="text-primary-darken2">{{ t('actions.cancel') }}</span>
      <v-icon
        end
        size="medium"
        class="text-primary-darken2"
      >
        fa:far fa-times
      </v-icon>
    </v-btn>
    <v-btn
      class="tw-grow"
      color="primary-darken2"
      data-testid="save-button"
      type="submit"
      :disabled="disableSave"
      variant="elevated"
      @click="$emit('save')"
    >
      {{ t('actions.save') }}
      <v-icon
        end
        size="medium"
      >
        fa:far fa-check
      </v-icon>
    </v-btn>
  </div>
</template>
