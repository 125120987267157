<script setup lang="ts">
import type { Attribute, VueComponentType } from '@/vuex/component_attributes_editor/types'
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import ComponentAttributeCertificate from './ComponentAttributeCertificate.vue'
import ComponentAttributeFloat from './ComponentAttributeFloat.vue'
import ComponentAttributeJSON from './ComponentAttributeJSON.vue'
import ComponentAttributeScheduler from './ComponentAttributeScheduler/ComponentAttributeScheduler.vue'
import ComponentAttributeString from './ComponentAttributeString.vue'
import ComponentHeader from './ComponentHeader.vue'
import type { ComponentInProjectListItemData } from '@/vuex/components_in_project/types'
import SearchInput from '@/components/SearchInput.vue'
import { useI18n } from 'vue-i18n'

// #region INITIALIZATIONS OF PROPS AND COMPOSABLES
interface Props {
  attributes: Attribute[],
  componentInProject: ComponentInProjectListItemData,
  loading: boolean
}

const props = defineProps<Props>()

const emits = defineEmits<{
  'set-attribute-error': [ value: Attribute ],
  'update:attribute': [ value: Attribute]
}>()

const dialogState = defineModel()

const { t, locale } = useI18n()

function getVueComponentType (attributeType: VueComponentType) {
  switch (attributeType) {
    case 'float':
      return ComponentAttributeFloat
    case 'json':
      return ComponentAttributeJSON
    case 'certificate':
      return ComponentAttributeCertificate
    case 'schedule':
      return ComponentAttributeScheduler
    default:
      return ComponentAttributeString
  }
}
// #endregion

// #region COMPONENT ATTRIBUTES
const search = ref('')
const filteredAttributes = computed(() => {
  if (!search.value) return props.attributes

  const attributeName = locale.value === 'de' ? 'nameDE' : 'nameEN'

  return props.attributes.filter((attribute) => {
    return attribute[attributeName]?.toLowerCase().includes(search.value.toLowerCase())
  })
})

watch(dialogState, (newDialogState) => {
  if (newDialogState) {
    search.value = ''
  }
})
// #endregion

// #region ADD BORDER TO SCROLLABLE DIV
const scrollableDiv = ref<HTMLDivElement|null>(null)
const isDivScrolled = ref(false)

const handleScroll = () => {
  if (scrollableDiv.value) {
    isDivScrolled.value = scrollableDiv.value.scrollTop > 0
  }
}

watch(scrollableDiv, () => {
  if (scrollableDiv.value !== null && !props.loading) {
    scrollableDiv.value.addEventListener('scroll', handleScroll)
  }
}, { once: true })

onBeforeUnmount(() => {
  if (scrollableDiv.value) {
    scrollableDiv.value.removeEventListener('scroll', handleScroll)
  }
})
// #endregion
</script>

<template>
  <v-dialog
    v-model="dialogState"
    data-testid="component-attributes-editor"
    width="640"
    max-height="calc(100vh - 80px)"
    content-class="aedifion-box-shadow-no-border tw-absolute tw-top-4"
  >
    <v-card
      class="tw-p-0 tw-border-0"
    >
      <ComponentHeader
        :title="componentInProject.title"
        :description="componentInProject.description"
      >
        <template #default>
          <v-btn
            class="tw-ml-auto"
            icon
            data-testid="dialog-close-button"
            size="14"
          >
            <v-icon
              size="14"
              @click="dialogState = false"
            >
              fa:far fa-xmark-large
            </v-icon>
          </v-btn>
        </template>
      </ComponentHeader>
      <div class="tw-p-6 tw-pb-0 bg-neutral-lighten3 tw-font-semibold">
        <h5 class="tw-mb-6 text-h5">
          {{ t('componentAttributes') }}
        </h5>
        <div class="tw-flex tw-items-center tw-mb-6">
          <SearchInput
            v-model="search"
            :color="'neutral-darken2'"
            :placeholder="t('search')"
            class="tw-mr-2"
          />
          <v-tooltip
            :text="t('analysis_tooltip')"
            location="end"
            content-class="aedifion-tooltip text-neutral-darken3 tw-max-w-[249px]"
          >
            <template
              #activator="{ props: analysisTooltipProps }"
            >
              <v-btn
                v-tooltip:end="'Tooltip at the end'"
                v-bind="analysisTooltipProps"
                variant="outlined"
                color="neutral-lighten1"
                class="text-neutral-darken1 bg-neutral-lighten3 tw-flex tw-items-center hover:tw-cursor-default"
              >
                <span class="tw-block text-neutral-darken1">{{ t('analysis') }}</span>
                <v-icon
                  class="tw-ml-2 tw-block text-neutral-darken1"
                  size="14"
                >
                  fa:far fa-chevron-down
                </v-icon>
              </v-btn>
            </template>
          </v-tooltip>
        </div>
      </div>
      <div
        v-if="!props.loading"
        ref="scrollableDiv"
        :class="['tw-h-full tw-overflow-y-auto tw-overflow-x-hidden tw-pt-0 tw-pb-5 tw-px-6 tw-border-t tw-border-transparent bg-neutral-lighten3', { 'scrollable-div': isDivScrolled }]"
      >
        <template
          v-for="attribute in filteredAttributes"
          :key="attribute.id"
        >
          <Component
            :is="getVueComponentType(attribute.vue_component_type)"
            :attribute="attribute"
            :component-in-project="componentInProject"
            @update:attribute="(attribute) => emits('update:attribute', attribute)"
            @set-attribute-error="(attribute) => emits('set-attribute-error', attribute)"
          />
        </template>
      </div>
      <div
        v-else
        class="tw-px-6 bg-neutral-lighten3 tw-h-full tw-overflow-y-auto"
        data-testid="skeleton-loader"
      >
        <div
          v-for="i in 25"
          :key="i"
          class="tw-flex"
        >
          <v-skeleton-loader
            class="tw-mt-2 tw-mr-2 tw-w-[50%] bg-neutral-lighten3"
            :height="40"
            type="text"
            :width="592"
          />
          <v-skeleton-loader
            class="tw-mt-2 tw-w-[50%]"
            :height="40"
            :width="592"
          />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<style lang="sass" scoped>
.scrollable-div
  transition: border-top 0.3s ease
  border-top: 1px solid rgb(var(--v-theme-neutral-lighten1)) !important

.title-border
  border-bottom: 1px solid rgb(var(--v-theme-neutral-lighten1))
</style>

<i18n locale="de" lang="json">
  {
    "analysis": "Analyse",
    "analysis_tooltip": "Filtern von Attributen nach Analyse ist ein kommendes Feature.",
    "componentAttributes": "Komponentenattribute",
    "search": "Suche"
  }
</i18n>
<i18n locale="en" lang="json">
  {
    "analysis": "Analysis",
    "analysis_tooltip": "Filtering attributes by analysis is an upcoming feature.",
    "componentAttributes": "Component Attributes",
    "search": "Search"
  }
</i18n>
