import { aedifionApi, ComponentInProject, ComponentInProjectWithContext, ComponentsInProject, TimeseriesWithContext } from '@aedifion.io/aedifion-api'
import { OPERATIONAL_STATE_PIN, SYSTEM_COMPONENT_ALPHANUMERIC_ID } from '@/settings'
import { ActionTree } from 'vuex'
import { getHashIdOfPinByAlphanumericId } from '@/utils/helpers/components'
import i18n from '@/i18n'
import { reportError } from '@/utils/helpers/errors'
import { resetStoreState } from '../status_and_alerts/state'
import { RootState } from '../types'
import { showErrorNotification } from '@/utils/helpers/notifications'
import { StatusAndAlertsState } from './types'
import { unPaginate } from '@/vuex/helpers'
import { validateNotNullish } from '@/utils/helpers/validate'

export default {

  clear: ({ state }) => {
    resetStoreState(state)
  },

  fetchSystemComponents: async ({ commit, dispatch, rootGetters }): Promise<void> => {
    const projectId = validateNotNullish(
      rootGetters['projects/currentProjectId'] as number|null,
      { errorMessage: i18n.global.t('notifications.errors.no_project_selected') as string },
    )

    commit('SET_LOADING_SYSTEM_COMPONENTS', true)
    try {
      const getProjectComponentsParamsMap = new Map<'project_id' | 'page' | 'per_page' | 'search' | 'filter', string|number|undefined>([
        ['project_id', projectId],
        ['page', 1],
        ['per_page', 100],
        ['search', undefined],
        ['filter', `alphanumeric_id=${SYSTEM_COMPONENT_ALPHANUMERIC_ID}`],
      ])

      type GetProjectComponentsParams = Parameters<typeof aedifionApi.Project.getProjectComponents>

      const unPaginatedRequest = async (params: GetProjectComponentsParams): Promise<ComponentsInProject> => aedifionApi.Project.getProjectComponents(...params)
      const systemComponentsUnpaginated = await unPaginate(unPaginatedRequest, getProjectComponentsParamsMap)

      const systemComponentsResponses: PromiseSettledResult<ComponentInProjectWithContext>[] = await Promise.allSettled(systemComponentsUnpaginated.items!.map((systemComponentItem: ComponentInProject) => {
        return aedifionApi.Project.getProjectComponent(projectId, systemComponentItem.id!)
      }))
      const systemComponentsFulfilled: ComponentInProjectWithContext[] = []
      for (const systemComponentPromiseSettled of systemComponentsResponses) {
        if (systemComponentPromiseSettled.status === 'fulfilled') {
          systemComponentsFulfilled.push(systemComponentPromiseSettled.value)
        }
      }
      commit('SET_SYSTEM_COMPONENTS', systemComponentsFulfilled)
      dispatch('fetchSystemOperationalState')
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.component_data') })}`)
      reportError(error)
    } finally {
      commit('SET_LOADING_SYSTEM_COMPONENTS', false)
    }
  },

  fetchSystemOperationalState: async ({ commit, state, rootGetters }): Promise<void> => {
    const projectId = validateNotNullish(
      rootGetters['projects/currentProjectId'] as number|null,
      { errorMessage: i18n.global.t('notifications.errors.no_project_selected') as string },
    )

    if (!state.systemComponents || state.systemComponents.length === 0) return
    commit('SET_LOADING_SYSTEMS_OPERATIONAL_STATUS_CODES', true)
    try {
      const hashIds: string[] = []
      for (const systemComponent of state.systemComponents ?? []) {
        const mappedHashIds = getHashIdOfPinByAlphanumericId(systemComponent, OPERATIONAL_STATE_PIN)
        if (mappedHashIds !== null) {
          hashIds.push(mappedHashIds)
        }
      }

      if (hashIds.length > 0) {
        const operationalStates: Map<string, number|null> = new Map()
        const operationalStateTimeseries: TimeseriesWithContext[] = await aedifionApi.Project.getProjectTimeseries(projectId, hashIds, undefined, new Date(), 1, undefined, undefined, undefined, false, false)
        for (const timeseries of operationalStateTimeseries) {
          const timeseriesResponse: TimeseriesWithContext = timeseries
          operationalStates.set(timeseriesResponse.datapoint_hash_id!, timeseriesResponse.data![0].value! as number|null)
        }
        commit('SET_SYSTEM_COMPONENTS_OPERATIONAL_STATE', Array.from(operationalStates))
      } else {
        commit('SET_SYSTEM_COMPONENTS_OPERATIONAL_STATE', [])
      }
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.faults_data') })}`)
      reportError(error)
    } finally {
      commit('SET_LOADING_SYSTEMS_OPERATIONAL_STATUS_CODES', false)
    }
  },

} as ActionTree<StatusAndAlertsState, RootState>
