<i18n lang="json" locale="de">
{
  "nudge_body": {
    "step_1": "Nettogrundfläche eintragen",
    "step_2": "Zähler einrichten & Zählerstände eingeben",
    "step_3": "Analyse starten"
  },
  "nudge_button_title": "Jetzt Fläche eintragen!",
  "nudge_title": "Bereit für Energiedaten?"
}
</i18n>
<i18n lang="json" locale="en">
{
  "nudge_body": {
    "step_1": "Enter net floor area",
    "step_2": "Set up meter & enter meter readings",
    "step_3": "Start analysis"
  },
  "nudge_button_title": "Enter area now!",
  "nudge_title": "Ready for energy data?"
}
</i18n>

<template>
  <div
    class="layout-wrapper asset-overview__wrapper"
    :style="[showCockpitNudge ? { 'overflow': 'hidden' } : {}]"
  >
    <div :class="{ 'cockpitNudgeBlur': showCockpitNudge }">
      <PageHeader
        title-key="links.meta.title.asset_overview"
      />
      <v-row class="mb-0 mx-n1 mt-n3">
        <v-col
          class="px-1 overflow-x-auto"
          :cols="colsForAssetProfile"
        >
          <AssetProfileCard
            :assigned-number-of-cols="colsForAssetProfile"
            class="flex-grow-1 card-dimensions"
            :loading="isLoadingBuildingComponent"
            :project="project"
          />
        </v-col>
        <v-col
          class="px-1"
          cols="12"
          :md="hasElevateReport ? 6 : 12"
          :lg="hasElevateReport ? 6 : 3"
          xl="3"
        >
          <AssetGoalCard
            :building-component="buildingComponent"
            class="flex-grow-1 card-dimensions"
            :loading="isLoadingAnalyses"
          />
        </v-col>
        <v-col
          v-if="hasElevateReport"
          class="px-1"
          cols="12"
          :md="hasElevateReport ? 6 : 12"
          xl="3"
        >
          <ElevateReportCard class="flex-grow-1 card-dimensions" />
        </v-col>
      </v-row>
      <AssetAnalysis class="px-2" />
    </div>
    <CockpitNudge
      :button-link="{ name: 'asset-profile', params: { project: currentProjectId.toString() } }"
      :button-title="t('nudge_button_title')"
      :name="t('links.meta.title.asset_overview')"
      :title="t('nudge_title')"
      :value="showCockpitNudge"
    >
      <ol class="cockpit-nudge-steps">
        <li>{{ t('nudge_body.step_1') }}</li>
        <li>{{ t('nudge_body.step_2') }}</li>
        <li>{{ t('nudge_body.step_3') }}</li>
      </ol>
    </CockpitNudge>
  </div>
</template>

<script lang="ts">
import { AnalyticsResultsForComponentResultIds, ComponentInProjectWithContext, SummaryKPIResult } from '@aedifion.io/aedifion-api'
import { NavigationGuardNext, RouteLocation } from 'vue-router'
import AssetAnalysis from './AssetAnalysis/index.vue'
import AssetGoalCard from './AssetGoalCard.vue'
import AssetProfileCard from './AssetProfileCard.vue'
import CockpitNudge from '@/components/CockpitNudge.vue'
import { defineComponent } from 'vue'
import ElevateReportCard from './ElevateReportCard.vue'
import i18n from '@/i18n'
import { isAssetOverviewSetUp } from '@/utils/helpers/viewStatus'
import { mapGetters } from 'vuex'
import { onInitialApiRequestsCompleted } from '@/utils/helpers/hooks'
import PageHeader from '@/components/PageHeader.vue'
import { removeResponseFromCache } from '@/services/database/apiRequestsCache'
import { storeToRefs } from 'pinia'
import { useAssetOverviewStore } from '@/stores/views/AssetOverview'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'AssetOverview',

  components: {
    AssetAnalysis,
    AssetGoalCard,
    AssetProfileCard,
    CockpitNudge,
    ElevateReportCard,
    PageHeader,
  },

  async beforeRouteUpdate (to: RouteLocation, from: RouteLocation, next: NavigationGuardNext) {
    if (to.params.project && from.params.project && to.params.project !== from.params.project) {
      await this.ensureRequiredDataIsLoaded()
    }
    next()
  },

  setup () {
    const { t } = useI18n()
    const assetOverviewStore = useAssetOverviewStore()

    const { fetchAllKPIAggregations } = assetOverviewStore
    const { kpiAggregations } = storeToRefs(assetOverviewStore)

    return { fetchAllKPIAggregations, kpiAggregations, t }
  },

  computed: {
    ...mapGetters({
      analysisResult: 'analysis_instances/analysisResult',
      currentProjectId: 'projects/currentProjectId',
      isLightVersion: 'asset_overview/isLightVersion',
      isLoadingAnalysisResult: 'analysis_instances/isLoadingAnalysisResult',
      project: 'projects/currentProject',
      projectId: 'projects/currentProjectId',
    }),

    buildingComponent (): ComponentInProjectWithContext|null {
      return this.$store.getters['building_analyses/buildingComponentOfProject'](this.projectId)
    },

    colsForAssetProfile (): number {
      if (this.$vuetify.display.lg) {
        if (this.isLightVersion) return 9
        else return this.hasElevateReport ? 12 : 9
      } else if (this.$vuetify.display.xl) {
        if (this.isLightVersion) return 9
        else return this.hasElevateReport ? 6 : 9
      } else {
        return 12
      }
    },

    hasElevateReport (): boolean {
      return this.$store.getters['files/hasElevateReport']
    },

    isLoadingAnalyses (): boolean {
      return this.$store.getters['building_analyses/isLoadingAnalysesOfProject'](this.projectId)
    },

    isLoadingBuildingComponent (): boolean {
      return this.$store.getters['building_analyses/isLoadingBuildingComponentOfProject'](this.projectId)
    },

    showCockpitNudge (): boolean {
      return !isAssetOverviewSetUp(this.projectId)
    },
  },

  created () {
    onInitialApiRequestsCompleted(() => {
      this.ensureRequiredDataIsLoaded()
    })
  },

  methods: {
    async ensureRequiredDataIsLoaded (): Promise<void> {
      this.$store.dispatch('files/fetchElevateReports')
      if (!this.kpiAggregations.size) {
        this.fetchAllKPIAggregations()
      }
      if (!this.$store.getters['building_analyses/isBuildingComponentOfProjectLoaded'](this.projectId)) {
        await this.$store.dispatch('building_analyses/fetchBuildingComponentForProject', this.projectId)
      }
      if (!this.$store.getters['building_analyses/areAnalysesOfProjectLoaded'](this.projectId)) {
        await this.$store.dispatch('building_analyses/fetchBuildingAnalysesForProject', this.projectId)
      }
      if (this.buildingComponent) {
        const apiHost = window.configuration.AEDIFION_API_URL
        const resultsRequestUrl = `${apiHost}/v2/analytics/componentInProject/${this.buildingComponent.id}/results?project_id=${this.projectId}&language=${i18n.global.locale.value}`
        removeResponseFromCache('GET', resultsRequestUrl)
        this.$store.dispatch('building_analyses/fetchBuildingAnalysesForProject', this.projectId)
      }
    },

    kpiResult (kpiKey: string): AnalyticsResultsForComponentResultIds|null {
      return this.$store.getters['building_analyses/analysisOfProject'](this.projectId, kpiKey)
    },

    summaryKPI (kpiKey: string): SummaryKPIResult|null {
      return this.kpiResult(kpiKey)?.summary_kpi ?? null
    },
  },
})
</script>

<style lang="sass" scoped>
@use '~/vuetify/settings' as vuetifySettings

.asset-overview__wrapper
  & .tabs
    height: 120px !important
    @media #{map-get(vuetifySettings.$display-breakpoints, 'lg-and-up')}
      max-width: 20%

  .cockpitNudgeBlur
    -webkit-filter: blur(2px)
    -moz-filter: blur(2px)
    -o-filter: blur(2px)
    -ms-filter: blur(2px)
    filter: blur(2px)

  .card-dimensions
    max-height: 225px

ol.cockpit-nudge-steps
  padding: 0
  list-style-type: decimal
  list-style-position: inside
</style>
