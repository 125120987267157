import { aedifionApi, ListOfEndpoints } from '@aedifion.io/aedifion-api'
import { ActionTree } from 'vuex'
import { EndpointsState } from './types'
import i18n from '@/i18n'
import { reportError } from '@/utils/helpers/errors'
import { RootState } from '../types'
import { showErrorNotification } from '@/utils/helpers/notifications'

export default {
  async fetchEndpoints ({ commit }) {
    commit('SET_LOADING', true)
    try {
      const endpoints: ListOfEndpoints = await aedifionApi.Meta.getEndpoints()
      commit('SET_ENDPOINTS', endpoints)
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.endpoints.fetch')}`)
      reportError(error)
    } finally {
      commit('SET_LOADING', false)
    }
  },
} as ActionTree<EndpointsState, RootState>
