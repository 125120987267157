import { Company, CompanyRoles, CompanyWithContext, CompanyWithContextProjects, CompanyWithContextUsers, ShortUser, UpdateCompany } from '@aedifion.io/aedifion-api'
import { computed, ref } from 'vue'
import { showErrorNotification, showSuccessNotification } from '@/utils/helpers/notifications'
import { defineStore } from 'pinia'
import i18n from '@/i18n'
import { reportError } from '@/utils/helpers/errors'
import { useCompanyApiStore } from '@aedifion.io/pinia-aedifion-api-stores'

export type ProjectWithUsersCount = CompanyWithContextProjects & { usersCount: number }

export type ShortUserWithTitle = ShortUser & { title: string }

export const useAdministrationStore = defineStore('administration', () => {
  const companyApiStore = useCompanyApiStore()

  const loading = ref(false)
  const updatingAvatar = ref(false)
  const company = ref<Company | null>(null)
  const projects = ref<CompanyWithContextProjects[] | null>(null)
  const users = ref<CompanyWithContextUsers[] | null>(null)

  const companyRoles = computed<CompanyRoles[]>(() => {
    return company.value?.roles?.filter((companyRole) => {
      // the only relevant role is the admin
      return companyRole.name === 'admin'
    }) ?? []
  })

  const displayedCompanyRoles = computed<CompanyRoles[]>(() => {
    return companyRoles.value.map((companyRole) => {
      if (companyRole.name === 'admin') {
        return { ...companyRole, name: i18n.global.t('administration.administrator') as string }
      }
      return companyRole
    })
  })

  const projectsGetter = computed<CompanyWithContextProjects[]>(() => {
    return projects.value ?? []
  })

  const usersGetter = computed<CompanyWithContextUsers[]>(() => {
    return users.value ?? []
  })

  const getProject = computed(() => (id: number): CompanyWithContextProjects | null => {
    return projects.value?.find((project) => {
      return project.id === id
    }) ?? null
  })

  const projectsWithUsersCount = computed<ProjectWithUsersCount[]>(() => {
    return projectsGetter.value.map((project) => {
      let usersCount = 0
      const projectRolesIds: number[] = []

      for (const role of project.roles ?? []) {
        if (role.id) {
          projectRolesIds.push(role.id)
        }
      }

      if (projectRolesIds.length) {
        usersCount = usersGetter.value.filter((user) => {
          return user.project_roles?.some((projectRoleIdOfUser) => {
            return projectRolesIds.includes(projectRoleIdOfUser)
          })
        }).length
      }

      return {
        ...project,
        usersCount,
      }
    })
  })

  const shortCompanyUsersWithTitle = computed<ShortUserWithTitle[]>(() => {
    return (users.value ?? []).map((user) => ({
      avatar_url: user.avatar_url,
      company_id: user.company_id!,
      email: user.email!,
      firstName: user.firstName!,
      id: user.id!,
      lastName: user.lastName!,
      title: `${user.firstName} ${user.lastName}`,
    }))
  })

  async function fetchCompanyWithContext (): Promise<void> {
    loading.value = true

    try {
      const companyWithContext = await companyApiStore.getCompany()
      if (companyWithContext.company && !('avatar_url' in companyWithContext.company)) {
        // this property’s reactivity breaks if it is not defined at all before
        // we assign it to company (not entirely sure why)
        companyWithContext.company.avatar_url = undefined
      }
      setCompany(companyWithContext)
    } catch (error) {
      const errorMessage = i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.company') }) as string
      showErrorNotification(errorMessage)
      reportError(error)
    } finally {
      loading.value = false
    }
  }

  function setCompany (companyWithContext: CompanyWithContext): void {
    company.value = companyWithContext.company ?? null
    projects.value = companyWithContext.projects ?? null
    users.value = companyWithContext.users ?? null
  }

  async function updateCompany (payload: UpdateCompany): Promise<void> {
    loading.value = true

    try {
      const result = await companyApiStore.putCompany({ company: payload })
      company.value = result.resource
      showSuccessNotification(i18n.global.t('notifications.success.update', { resource: i18n.global.t('notifications.resources.company_profile') }) as string)
    } catch (error) {
      const errorMessage = i18n.global.t('notifications.errors.update', { resource: i18n.global.t('notifications.resources.company_profile') }) as string
      showErrorNotification(errorMessage)
      reportError(error)
    } finally {
      loading.value = false
    }
  }

  async function updateAvatar (avatar: File): Promise<void> {
    updatingAvatar.value = true

    try {
      const result = await companyApiStore.postAvatar({ avatar })
      if (company.value) {
        company.value.avatar_url = result.resource.avatar_url
      }
    } catch (error) {
      const errorMessage = i18n.global.t('notifications.errors.update', { resource: i18n.global.t('notifications.resources.avatar') }) as string
      showErrorNotification(errorMessage)
      reportError(error)
    } finally {
      updatingAvatar.value = false
    }
  }

  async function deleteAvatar (): Promise<void> {
    updatingAvatar.value = true

    try {
      await companyApiStore.deleteAvatar()
      if (company.value) {
        company.value.avatar_url = undefined
      }
    } catch (error) {
      const errorMessage = i18n.global.t('notifications.errors.update', { resource: i18n.global.t('notifications.resources.avatar') }) as string
      showErrorNotification(errorMessage)
      reportError(error)
    } finally {
      updatingAvatar.value = false
    }
  }

  function pushUser (newUser: CompanyWithContextUsers): void {
    if (users.value && !users.value.some((user) => user.id === newUser.id)) {
      users.value.push(newUser)
    }
  }

  function updateUser (userId: number, propertiesToUpdate: Partial<CompanyWithContextUsers>): void {
    const user = users.value?.find((user) => user.id === userId)
    if (user) {
      users.value?.splice(users.value?.indexOf(user), 1, { ...user, ...propertiesToUpdate })
    }
  }

  function removeUser (userId: number): void {
    users.value = users.value?.filter((user) => user.id !== userId) ?? null
  }

  return {
    company,
    companyRoles,
    deleteAvatar,
    displayedCompanyRoles,
    fetchCompanyWithContext,
    getProject,
    loading,
    projects: projectsGetter,
    projectsWithUsersCount,
    pushUser,
    removeUser,
    setCompany,
    shortCompanyUsersWithTitle,
    updateAvatar,
    updateCompany,
    updateUser,
    updatingAvatar,
    users: usersGetter,
  }
})
