<script setup lang="ts">
import { COCKPIT_NUDGE_GRADIENT } from '@theme/colors'
import EmptyStateForPage from '@/components/EmptyStateForPage.vue'
import FilterBar from './FilterBar.vue'
import LogsTable from './LogsTable.vue'
import PageHeader from '@/components/PageHeader.vue'
import { useAIControlsLogLogsStore } from '@/stores/views/AIControls/Log/logs'
import { useI18n } from 'vue-i18n'

const aiControlsLogLogsStore = useAIControlsLogLogsStore()

const { t } = useI18n()
</script>

<template>
  <div class="layout-wrapper">
    <PageHeader
      sticky
      title-key="links.meta.title.ai_controls_log"
    >
      <FilterBar
        class="ml-auto"
      />
    </PageHeader>
    <EmptyStateForPage
      v-if="aiControlsLogLogsStore.state?.logs?.length === 0"
      class="mt-n8"
      :icon="{
        name: 'fa:fal fa-clock',
        size: '40px',
        color: `linear-gradient(${COCKPIT_NUDGE_GRADIENT[0]}, ${COCKPIT_NUDGE_GRADIENT[1]}, ${COCKPIT_NUDGE_GRADIENT[2]})`
      }"
      :title="t('empty_state.title')"
      :description="t('empty_state.description')"
    />
    <LogsTable
      v-else
    />
  </div>
</template>

<i18n lang="json" locale="de">
{
  "empty_state": {
    "description": "Für die ausgewählte Controls App und den Zeitraum sind keine Logs vorhanden.",
    "title": "Keine Ergebnisse"
  }
}
</i18n>
<i18n lang="json" locale="en">
{
  "empty_state": {
    "description": "There are no logs available for the chosen Controls app and time period.",
    "title": "No results found"
  }
}
</i18n>
