import { computed, ref } from 'vue'
import { showErrorNotification, showSuccessNotification } from '@/utils/helpers/notifications'
import { defineStore } from 'pinia'
import i18n from '@/i18n'
import { reportError } from '@/utils/helpers/errors'
import { useUserApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import { useUserStore } from '@/stores/user'

export const useUserSecurityStore = defineStore('userSecurity', () => {
  const userApiStore = useUserApiStore()
  const userStore = useUserStore()

  const isPasswordUpdatePending = ref(false)

  const email = computed<string | null>(() => {
    return userStore.user?.user?.email ?? null
  })

  async function updatePassword (payload: { currentPassword: string, newPassword: string }): Promise<boolean> {
    let success = false
    isPasswordUpdatePending.value = true

    try {
      await userApiStore.putPassword(payload)
      success = true
      showSuccessNotification(`${i18n.global.t('notifications.success.user.updatePassword')}`)
    } catch (error) {
      let errorMessage: string | null = null
      if ((error as Error & { status: number }).status === 422) {
        errorMessage = `${i18n.global.t('notifications.errors.user.currentPasswordIncorrect')}`
      } else if ((error as Error & { status: number }).status === 423) {
        errorMessage = `${i18n.global.t('notifications.errors.user.endpointLocked')}`
      } else {
        errorMessage = `${i18n.global.t('notifications.errors.user.updatePassword')}`
      }
      showErrorNotification(errorMessage)
      reportError(error)
    } finally {
      isPasswordUpdatePending.value = false
    }

    return success
  }

  return {
    email,
    isPasswordUpdatePending,
    updatePassword,
  }
})
