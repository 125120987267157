<i18n locale="de">
{
  "delete": "Löschen",
  "edit_attributes": "Eigenschaften bearbeiten",
  "edit_mapping": "Datenpunkte bearbeiten",
  "rename": "Umbenennen"
}
</i18n>
<i18n locale="en">
{
  "delete": "Delete",
  "edit_attributes": "Edit properties",
  "edit_mapping": "Edit datapoints",
  "rename": "Rename"
}
</i18n>

<template>
  <v-list-item>
    <div
      :class="[{'selected': isSelected}, 'selectable']"
    />
    <template #prepend>
      <v-avatar
        class="ml-4"
        :color="whitelabelColor(itemData.signalColor)"
        rounded-circle
        size="1.2rem"
      >
        <v-icon
          v-if="isSelected && !itemData.signalColor"
          class="tw-text-sm tw-mb-2"
          color="neutral-darken3"
        >
          fa:far fa-eye
        </v-icon>
      </v-avatar>
    </template>
    <v-list-item-title
      class="clamp-text-3 font-weight-medium pt-1 mb-1"
      data-cy="component-in-project-list-item"
    >
      {{ itemData.title }}
    </v-list-item-title>
    <v-list-item-subtitle
      v-if="itemData.description"
      class="clamp-text-3 text-subtitle-1 pb-1"
    >
      {{ itemData.description }}
    </v-list-item-subtitle>
    <template #append>
      <KebabMenu
        v-if="!readOnly"
        color="neutral-darken2"
        small
        :items="kebabMenuItems"
        @rename-option-click="$emit('componentlistitem:rename-component', itemData)"
        @edit-mapping-option-click="$emit('componentlistitem:edit-mapping', itemData)"
        @delete-option-click="$emit('componentlistitem:delete-component', itemData)"
        @edit-attributes-option-click="$emit('componentlistitem:edit-attributes', itemData)"
      />
    </template>
  </v-list-item>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { type ActionMenuItem } from '@/components/ActionMenu.vue'
import { ComponentInProjectListItemData } from '@/vuex/components_in_project/types'
import KebabMenu from '@/components/KebabMenu.vue'
import { useI18n } from 'vue-i18n'
import { whitelabelColor } from '@/filters/colors'

export default defineComponent({
  name: 'ComponentListItem',

  components: {
    KebabMenu,
  },

  props: {
    itemData: {
      required: true,
      type: Object as PropType<ComponentInProjectListItemData>,
    },
    readOnly: {
      required: false,
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  emits: [
    'componentlistitem:rename-component',
    'componentlistitem:edit-mapping',
    'componentlistitem:delete-component',
    'componentlistitem:edit-attributes',
  ],

  setup () {
    const { t } = useI18n()
    return { t }
  },

  data () {
    return {
      showRenameComponentDialog: false as boolean,
    }
  },

  computed: {
    isSelected (): boolean {
      return this.$store.getters['optimization/getSelectedComponent']?.id === this.itemData.id
    },

    kebabMenuItems (): Array<ActionMenuItem> {
      return [{
        id: 'rename',
        label: this.t('rename') as string,
      }, {
        id: 'edit-mapping',
        label: this.t('edit_mapping') as string,
      }, {
        id: 'delete',
        label: this.t('delete') as string,
      },
      {
        id: 'edit-attributes',
        label: this.t('edit_attributes') as string,
      }]
    },
  },

  methods: {
    whitelabelColor,
  },
})
</script>

<style lang="sass" scoped>
.clamp-text-3
  display: -webkit-box
  -webkit-line-clamp: 3
  -webkit-box-orient: vertical
  white-space: normal !important

.selected
  opacity: 0.12 !important
  background-color: rgb(var(--v-theme-primary)) !important
.selectable
  background-color: transparent
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0

:deep(.v-list-item__content)
  flex-grow: 1 !important
</style>
