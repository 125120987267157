import moment, { Moment } from 'moment'
import i18n from '@/i18n'

export function getDatePartAsString (dateTime: Date|moment.Moment|string): string {
  if (typeof dateTime === 'string') {
    return new Date(dateTime).toISOString().split('T')[0]
  }
  return dateTime.toISOString().split('T')[0]
}

function fixPrefixWordOfDuration (duration: string): string {
  const words = duration.split(' ').slice(-2)
  return `${i18n.global.t('for')} ${words[0]} ${words[1]}`
}

/**
 * Returns the duration of the given date in a humanized format.
 * @param date The duration will be calculated from this date.
 * @returns The duration in a humanized format.
 */
export function getHumanizedDuration (date: Moment): string {
  const now = moment()
  const locale = i18n.global.locale.value
  const duration = moment.duration(now.diff(date))

  moment.locale(locale)

  if (duration.asHours() < 1) {
    return `${i18n.global.t('for')} ${i18n.global.t('recently')}`
  }

  return fixPrefixWordOfDuration(moment(now).add(duration).fromNow(true))
}

/**
 * Returns the duration of the given date in a humanized format.
 * @param startDate The start date of the range.
 * @param endDate The end date of the range.
 * @returns A readable formatted duration.
 */
export function formatDateRange (startDate: string, endDate: string): string {
  const start = moment(startDate)
  const end = moment(endDate)

  const sameDay = start.isSame(end, 'day')
  const sameMonth = start.isSame(end, 'month')
  const sameYear = start.isSame(end, 'year')
  const currentYear = moment().isSame(start, 'year')

  let formattedRange = ''

  if (sameDay) {
    // If start and end are the same day
    formattedRange = start.format(currentYear ? i18n.global.t('date_ranges.MMM D') : i18n.global.t('date_ranges.YYYY MMM D'))
  } else if (!sameYear) {
    // Dates are in different years
    formattedRange = `${start.format(i18n.global.t('date_ranges.YYYY MMM D'))} – ${end.format(i18n.global.t('date_ranges.YYYY MMM D'))}`
  } else if (!sameMonth) {
    // Dates are in different months of the same year
    formattedRange = `${start.format(i18n.global.t('date_ranges.MMM D'))} – ${end.format(i18n.global.t('date_ranges.MMM D'))}`
  } else {
    // Dates are in the same month of the same year
    if (currentYear) {
      // Current year, same month
      if (i18n.global.locale.value === 'en') {
        formattedRange = `${start.format(i18n.global.t('date_ranges.MMM D'))} – ${end.format(i18n.global.t('date_ranges.D'))}`
      } else {
        formattedRange = `${start.format(i18n.global.t('date_ranges.D'))} – ${end.format(i18n.global.t('date_ranges.MMM D'))}`
      }
    } else {
      // Not current year, same month
      if (i18n.global.locale.value === 'en') {
        formattedRange = `${start.format(i18n.global.t('date_ranges.MMM D'))} – ${end.format(i18n.global.t('date_ranges.D'))}`
      } else {
        formattedRange = `${start.format(i18n.global.t('date_ranges.D'))} – ${end.format(i18n.global.t('date_ranges.MMM D'))}`
      }
    }
  }

  return formattedRange
}
