import { AnalysisResultSummaries } from '@aedifion.io/aedifion-api'
import moment from 'moment'
import { computed, ref } from 'vue'
import vuexStore from '@/vuex'

export type AnalysisResultItem = AnalysisResultSummaries | { type: 'subheader', title: string }

export default function useAnalysisResultHistoryItems (initialYear?: number) {
  const analysisResults = computed(() => vuexStore.state.analysis_instances.analysisResults ?? [])

  const selectedYear = ref(initialYear ?? moment().year())

  /**
   * Returns a sorted list of analysis results, where monthly results are summarized to one result per month.
   */
  const analysisResultItems = computed(() => {
    const results: AnalysisResultSummaries[] = []
    const monthlyResults: Map<string, AnalysisResultSummaries> = new Map()
    for (const result of analysisResults.value) {
      const startMoment = moment(result.input_parameters.start)
      const endMoment = moment(result.input_parameters.end)
      const isMonthly = endMoment.diff(startMoment, 'days') > 7

      if (isMonthly) {
        const month = startMoment.format('YYYY-MM')
        if (monthlyResults.has(month)) {
          const existingResult = monthlyResults.get(month)
          if (existingResult) {
            monthlyResults.set(month, result)
          }
        } else {
          monthlyResults.set(month, result)
        }
      } else {
        results.push(result)
      }
    }

    for (const [, result] of monthlyResults) {
      results.push(result)
    }

    return results
      .filter(result => moment(result.input_parameters.start).year() === selectedYear.value)
      .sort((a, b) => moment(b.input_parameters.start).diff(moment(a.input_parameters.start)))
  })

  /**
   * Returns a list of analysis results, where monthly results are summarized to one result per month.
   * Creates subheader items for each month if only weekly results for a month are available.
   * If monthly and weekly results are available for a month, the monthly result is shown first as a replacement
   * for an additional subheader.
   */
  const analysisResultDropdownItems = computed<AnalysisResultItem[]>(() => {
    const dropdownItems: AnalysisResultItem[] = []
    const groupedResults = analysisResultItems.value.reduce((acc, result) => {
      const month = moment(result.input_parameters.start).format('YYYY MMMM')
      if (!acc[month]) {
        acc[month] = []
      }
      acc[month].push(result)
      return acc
    }, {} as Record<string, AnalysisResultSummaries[]>)

    for (const [, results] of Object.entries(groupedResults)) {
      const isMonthly = results.findIndex(result => moment(result.input_parameters.end).diff(moment(result.input_parameters.start), 'days') > 7)
      if (isMonthly > -1) {
        results.sort((a, b) => {
          const durationA = moment(a.input_parameters.end).diff(moment(a.input_parameters.start))
          const durationB = moment(b.input_parameters.end).diff(moment(b.input_parameters.start))
          return durationB - durationA
        })
        dropdownItems.push(...results)
      } else {
        dropdownItems.push({ type: 'subheader', title: results[0].input_parameters.start } as AnalysisResultItem)
        dropdownItems.push(...results)
      }
    }

    return dropdownItems
  })

  const hasOlderResults = computed(() => {
    return analysisResults.value.some(result => moment(result.input_parameters.start).year() < selectedYear.value)
  })

  const hasNewerResults = computed(() => {
    return analysisResults.value.some(result => moment(result.input_parameters.start).year() > selectedYear.value)
  })

  return {
    analysisResultItems,
    analysisResultDropdownItems,
    hasNewerResults,
    hasOlderResults,
    selectedYear,
  }
}
