<script setup lang="ts">
import AskAedifionDialog from '@/components/AskAedifionDialog.vue'
import { COCKPIT_NUDGE_GRADIENT } from '@theme/colors'
import { RouteLocation } from 'vue-router'
import { useI18n } from 'vue-i18n'

type Props = {
  buttonLink?: RouteLocation,
  buttonTitle?: string,
  name: string,
  title: string,
  value: boolean
}

defineProps<Props>()

const { t } = useI18n()

const customNudge = {
  background: `linear-gradient(${COCKPIT_NUDGE_GRADIENT[0]}, ${COCKPIT_NUDGE_GRADIENT[1]}, ${COCKPIT_NUDGE_GRADIENT[2]})`,
}
</script>

<template>
  <div
    :class="{ overlay: value }"
  />
  <div
    v-if="value"
    data-cy="cockpit-nudge"
    class="cockpit-nudge text-neutral-lighten5"
    :style="customNudge"
  >
    <span
      class="text-center cockpit-nudge-icon mx-auto mb-4 px-3 py-3 d-flex justify-center"
      :style="customNudge"
    >
      <v-icon
        center
        color="neutral-lighten5"
      >
        fa:far fa-lock
      </v-icon>
    </span>

    <v-container>
      <v-row
        align="center"
        justify="center"
      >
        <v-col>
          <div class="mb-2">
            <h2
              class="text-h3 text-center mb-2"
            >
              {{ title }}
            </h2>
            <p
              class="text-body-1 text-center mb-0"
            >
              <slot>
                {{ t('ask_aedifion_default_text') }}
              </slot>
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col>
          <div class="my-2 d-flex justify-center">
            <v-btn
              v-if="buttonLink && buttonTitle"
              class="text-accent"
              color="neutral-lighten5"
              :to="buttonLink"
            >
              {{ buttonTitle }}
            </v-btn>
            <AskAedifionDialog
              v-else
              :message="t('ask_aedifion_message', { cockpitName: name })"
              :title="t('ask_aedifion_title', { cockpitName: name })"
            >
              <template #button="buttonProps">
                <v-btn
                  v-bind="buttonProps"
                  class="text-accent"
                  color="neutral-lighten5"
                >
                  {{ t('support_call_to_action.support') }}
                </v-btn>
              </template>
            </AskAedifionDialog>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped lang="sass">
.cockpit-nudge
  position: fixed
  height: 50%
  width: calc(100vw - 200px)
  bottom: 0
  left: 0
  z-index: 2
  transform: translateX(200px)
.cockpit-nudge-icon
  top: -26px
  border: 2px solid rgb(var(--v-theme-neutral-lighten5))
  border-radius: 30px
  position: relative
  width: 53px
.overlay
  background-color: rgba(33,33,33,0.46)
  position: absolute
  left: 0
  top: 0
  height: 100%
  width: calc(100vw - 200px)
</style>

<i18n locale="de">
{
  "ask_aedifion_message": "Möchtest du die {cockpitName} nutzen? Dann melde dich bei uns und wir richten sie dir schnellstmöglich ein.",
  "ask_aedifion_title": "{cockpitName} einrichten",
  "ask_aedifion_default_text": "Hol dir das Update und kontaktiere das Support Team."
}
</i18n>
<i18n locale="en">
{
  "ask_aedifion_message": "Would you like to use the {cockpitName}? Contact us and we will set it up for you as soon as possible.",
  "ask_aedifion_title": "Setup {cockpitName}",
  "ask_aedifion_default_text": "Get the update and contact the support team."
}
</i18n>
