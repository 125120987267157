<script setup lang="ts">
import { type DateOrRangeString } from '@/utils/types'
import StyledDatepicker from '@/components/StyledDatepicker.vue'
import { useAIControlsLogAppsStore } from '@/stores/views/AIControls/Log/apps'
import { useAIControlsLogLogsStore } from '@/stores/views/AIControls/Log/logs'
import { useI18n } from 'vue-i18n'
import ControlsAppSelector from '@/views/AIControls/ControlsAppSelector.vue'
import { useSelectControlsApp } from '../Composables/useSelectControlsApp'
import { useAppStore } from '@/stores/app'
const appsStore = useAIControlsLogAppsStore()
const logsStore = useAIControlsLogLogsStore()
const appStore = useAppStore()

const { t } = useI18n()

function onTimePeriodUpdated (newTimePeriod: DateOrRangeString) {
  logsStore.setTimePeriod(newTimePeriod)
}

// #region CONTROL APPS SELECTOR
const { debouncedSearchApps, fetchMoreControlsApps, selectControlsApp, apps, search } = useSelectControlsApp()
// #endregion
</script>

<template>
  <div class="d-flex gap">
    <ControlsAppSelector
      v-model:search="search"
      :selected-controls-app="appsStore.state.selectedControlsApp"
      :control-apps="apps"
      :show-apps-state="false"
      :project-id="appStore.projectId"
      :navigation-enabled="false"
      select-first-controls-app
      :fetch-more-controls-apps="fetchMoreControlsApps"
      @search-controls-apps="debouncedSearchApps"
      @select-controls-app="selectControlsApp"
    >
      <template #item-action="{ item }">
        <span
          class="text-subtitle-2 text-uppercase tw-ml-auto tw-p-0 text-neutral-darken1"
        >{{ item.raw.custom?.algorithm }}</span>
      </template>
    </ControlsAppSelector>
    <StyledDatepicker
      :model-value="logsStore.state.timePeriod"
      :label="t('time_period')"
      range
      :format="t('date_format')"
      class="tw-w-fit"
      :enable-time-picker="false"
      position="right"
      :multi-calendars="{ solo: true }"
      :max-date="new Date()"
      @update:model-value="onTimePeriodUpdated"
    />
  </div>
</template>

<style lang="sass" scoped>
.controls-apps-drop-down
  width: 500px

.time-period-picker
  min-width: fit-content
</style>

<i18n lang="json" locale="de">
{
  "date_format": "dd.MM.yyyy",
  "time_period": "Zeitraum"
}
</i18n>
<i18n lang="json" locale="en">
{
  "date_format": "MM/dd/yyyy",
  "time_period": "Time period"
}
</i18n>
