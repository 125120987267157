import { acceptHMRUpdate, defineStore } from 'pinia'
import { CellData, RowData } from '@/components/SummarizedContent/types'
import { ComponentInProjectAttribute, ComponentInProjectKPIAggregations, type ComponentInProjectWithContext, type ComponentsKPIAggregationResult, KPIAggregationItem, KPIResult, type User } from '@aedifion.io/aedifion-api'
import { computed, ref, toRaw } from 'vue'
import { type GetComponentsKpiAggregationPayload, useAnalyticsApiStore, useProjectApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import cloneDeep from 'lodash.clonedeep'
import { getFallbackName } from '@/utils/helpers/locale'
import i18n from '@/i18n'
import moment from 'moment'
import { reportError } from '@/utils/helpers/errors'
import { showErrorNotification } from '@/utils/helpers/notifications'
import { sortByKPI } from '@/stores/helpers/kpiAggregationHelpers'
import { unPaginate } from '@/vuex/helpers'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { validateNotNullish } from '@/utils/helpers/validate'
import vuexStore from '@/vuex'

type Keys = 'component' | 'type' | 'energy' | 'cost' | 'co2'

export enum CsvFileUploadState {
  Error = 'error',
  Success = 'success',
  Uploading = 'uploading',
  WaitingForUpload = 'waiting-for-upload',
}

const METERS_ALPHANUMERIC_IDS = ['GASM', 'ELM', 'HM', 'CM']

// According to Christian Reiners ->
// historically "virtual_heat_meter_analysis" just for the heat meter. Then support was added for other meter types like electricity etc.
// and the display name was renamed to "Virtual heat meter" analysis -> "Virtual meter" analysis, but the alphanumeric_id was kept as "virtual_heat_meter_analysis".
export const COST_KPI_ID = 'virtual_heat_meter_analysis.energy_cost_absolute'
export const CO2_KPI_ID = 'virtual_heat_meter_analysis.co2_emissions_absolute'
export const ENERGY_KPI_ID = 'virtual_heat_meter_analysis.energy_consumption_absolute'

export const useMeterStore = defineStore('meter', () => {
  const analyticsApiStore = useAnalyticsApiStore()
  const projectApiStore = useProjectApiStore()
  const appStore = useAppStore()
  const userStore = useUserStore()

  const componentsKpiAggregation = ref<ComponentsKPIAggregationResult | null>(null)
  const isLoadingComponentsKpiAggregation = ref(false)
  const csvFileUploadState = ref<CsvFileUploadState>(CsvFileUploadState.WaitingForUpload)
  const csvFileTemplateUrl = ref<string | null>(null)
  // The default date range is the last month
  const dateRange = ref<[start: Date, end?: Date]>([
    moment().subtract(1, 'month').toDate(),
  ])

  const componentsKpiAggregationFilled = computed(() => {
    if (!componentsKpiAggregation.value) return null

    const itemsWithinTimeRange: ComponentInProjectKPIAggregations[] = []
    const aggregatedKpisOverview: KPIResult[] = []
    const clonedComponentsKpiAggregation = cloneDeep(componentsKpiAggregation.value)

    for (const item of clonedComponentsKpiAggregation.items) {
      let isDateRangeFilled = true

      for (const kpiAggregation of item.kpi_aggregations) {
        if (kpiAggregation.time_range_info.filled === false) {
          isDateRangeFilled = false
          break
        }
      }

      if (isDateRangeFilled) {
        itemsWithinTimeRange.push(cloneDeep(item))

        if (item.component_in_project.attributes?.find((attribute: ComponentInProjectAttribute) => attribute.key?.includes('CALC_COST'))?.value === 'True') {
          for (const kpiAggregation of item.kpi_aggregations) {
            if (aggregatedKpisOverview.findIndex((kpi) => kpi.context === kpiAggregation.kpi.context) === -1) {
              aggregatedKpisOverview.push(kpiAggregation.kpi)
            } else {
              const index = aggregatedKpisOverview.findIndex((kpi) => kpi.context === kpiAggregation.kpi.context)
              aggregatedKpisOverview[index].value = (aggregatedKpisOverview[index].value ?? 0) + (kpiAggregation.kpi.value ?? 0)
            }
          }
        }
      } else {
        itemsWithinTimeRange.push({
          ...item,
          kpi_aggregations: [],
        })
      }
    }

    return {
      aggregated_kpis_overview: aggregatedKpisOverview,
      items: itemsWithinTimeRange,
      meta: componentsKpiAggregation.value.meta,
    }
  })

  const componentsKpiAggregationSorted = computed<ComponentInProjectKPIAggregations[]>(() => {
    if (!componentsKpiAggregationFilled.value?.items) return []

    // Copy the array in order to not mutate the original one
    const componentsKpiAggregationSorted = [...componentsKpiAggregationFilled.value.items].sort(sortByKPI(COST_KPI_ID))

    return componentsKpiAggregationSorted
  })

  const componentsKPIs = computed<RowData<Keys>[]>(() => {
    if (!componentsKpiAggregationSorted.value.length) return []

    const currentLanguage = computed(() => i18n.global.locale.value)

    const componentsKPIs: RowData<Keys>[] = componentsKpiAggregationSorted.value.map((item) => {
      const co2 = getKPIValue(item.kpi_aggregations, CO2_KPI_ID)
      const cost = getKPIValue(item.kpi_aggregations, COST_KPI_ID)
      const energy = getKPIValue(item.kpi_aggregations, ENERGY_KPI_ID)
      const isAnalogMeter = item.component_in_project.attributes?.find((attribute) => attribute.key?.includes('DATA_SRC'))?.value === 'user'

      return {
        co2,
        component: {
          chipText: getChipText(item.component_in_project.attributes ?? []),
          id: item.component_in_project.id,
          subtitle: getSubtitleForRow(item.component_in_project, currentLanguage.value),
          text: getFallbackName(item.component_in_project?.nameDE, item.component_in_project?.nameEN),
        },
        cost,
        energy,
        type: {
          custom: {
            isAnalogMeter,
          },
          text: isAnalogMeter ? 'Analog' : 'Digital',
        },
      }
    })

    return componentsKPIs
  })

  const meterSummary = computed(() => {
    if (!componentsKpiAggregationFilled.value?.aggregated_kpis_overview || componentsKpiAggregationFilled.value?.items.length === 1) return null

    const co2 = getKPIValue(componentsKpiAggregationFilled.value.aggregated_kpis_overview, CO2_KPI_ID)
    const energy = getKPIValue(componentsKpiAggregationFilled.value.aggregated_kpis_overview, ENERGY_KPI_ID)
    const cost = getKPIValue(componentsKpiAggregationFilled.value.aggregated_kpis_overview, COST_KPI_ID)

    const component: CellData = {
      subtitle: `${i18n.global.t('meter_view.number_of_meters', { count: componentsKpiAggregationFilled.value.meta.total_items })}`,
      subtitleTooltip: {
        icon: 'fa:far fa-info-circle',
        text: i18n.global.t('meter_view.tooltip_text') as string,
      },
      text: i18n.global.t('meter_view.total') as string,
    }

    return {
      co2,
      component,
      cost,
      energy,
    }
  })

  async function fetchMeterData () {
    try {
      isLoadingComponentsKpiAggregation.value = true
      const requestsToFetch = []

      // Fetch the components if they are not already in the store, this can happen if the user navigates directly to the meter view through a link
      if (vuexStore.state.components.components === null) {
        requestsToFetch.push(vuexStore.dispatch('components/fetchComponents'))
      }
      // Fetch the building component if it is not already in the store
      if (vuexStore.getters['building_analyses/buildingComponentOfProject'](appStore.projectId) === null) {
        requestsToFetch.push(vuexStore.dispatch('building_analyses/fetchBuildingComponentForProject', appStore.projectId))
      }

      await Promise.all(requestsToFetch)

      const componentIds: number[] = []

      // We only want to fetch the KPI aggregation for the meters
      for (const component of vuexStore.state.components.components!) {
        if (METERS_ALPHANUMERIC_IDS.includes(component.alphanumeric_id!)) {
          componentIds.push(component.id!)
        }
      }

      const userDetails: User = validateNotNullish(userStore.userDetails)

      const payload: GetComponentsKpiAggregationPayload = {
        componentAggregation: 'sum',
        componentIds,
        currencySystem: userDetails?.currency_system,
        // we use the utcOffset(0, true) to get the date exactly at the start of the day, otherwise there would be a difference of 2 hours depending on the timezone
        // Sometimes the end date is not defined, in this case we set the end date to the end of the month of the start date
        end: dateRange.value[1]
          ? moment(moment(dateRange.value[1]).format('YYYY-MM')).add(1, 'month').startOf('month').utcOffset(0, true).toDate()
          : moment(moment(dateRange.value[0]).format('YYYY-MM')).add(1, 'month').startOf('month').utcOffset(0, true).toDate(),
        kpiIdentifiers: [ENERGY_KPI_ID, COST_KPI_ID, CO2_KPI_ID],
        language: i18n.global.locale.value as 'en'|'de' ?? 'de',
        page: 1,
        perPage: 100,
        projectId: appStore.projectId,
        start: moment(moment(dateRange.value[0]).format('YYYY-MM')).startOf('month').utcOffset(0, true).toDate(),
        timeAggregation: 'sum',
        unitsSystem: userDetails?.units_system,
      }

      const request = (payload: GetComponentsKpiAggregationPayload) => analyticsApiStore.getComponentsKpiAggregation(payload)

      const componentsKpiAggregationWithoutCost = unPaginate(request, payload)

      const componentsKpiAggregationWithoutCostResponse = await componentsKpiAggregationWithoutCost

      componentsKpiAggregation.value = {
        aggregated_kpis_overview: [],
        items: [...componentsKpiAggregationWithoutCostResponse.items],
        meta: { ...componentsKpiAggregationWithoutCostResponse.meta },
      }
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.meters') })}`)
      reportError(error)
    } finally {
      isLoadingComponentsKpiAggregation.value = false
    }
  }

  async function fetchCsvFileTemplateUrl () {
    try {
      csvFileTemplateUrl.value = (await projectApiStore.getFile({
        path: 'templates/meter-readings-upload.csv',
        projectId: appStore.projectId,
      })).download_link ?? null
    } catch {
      // fail silently
    }
  }

  async function uploadMeterCsvFile (file: File) {
    csvFileUploadState.value = CsvFileUploadState.Uploading
    try {
      await projectApiStore.postTimeseriesFile({
        file,
        format: 'csv',
        onError: 'abort',
        projectId: appStore.projectId,
      })
      csvFileUploadState.value = CsvFileUploadState.Success
    } catch (error) {
      csvFileUploadState.value = CsvFileUploadState.Error
    }
  }

  function resetCsvFileUploadState () {
    csvFileUploadState.value = CsvFileUploadState.WaitingForUpload
  }

  function clearMeterStore () {
    if (vuexStore.state.asset_overview.analogMetersToExecuteAnalysis.size > 0 && vuexStore.state.asset_overview.pendingAnalogMeterAnalysisExecutions === false && vuexStore.state.asset_overview.pendingAnalysisExecutions === false) {
      vuexStore.dispatch('asset_overview/triggerUserMeterAnalysesForAllAnalogMeters',
        {
          // We need to use toRaw() and copy the set to remove reactivity, otherwise the set will be empty since the asset_overview store will be cleared
          analogMeters: toRaw(new Set([...vuexStore.state.asset_overview.analogMetersToExecuteAnalysis])),
          projectId: appStore.projectId,
        })
      if (vuexStore.getters['asset_overview/userMeterAnalysisIsAvailable']) {
        vuexStore.dispatch('asset_overview/triggerUserMeterAnalyses', vuexStore.state.asset_overview.userMeters)
      }
    }
    componentsKpiAggregation.value = null
    resetCsvFileUploadState()
    csvFileTemplateUrl.value = null
  }

  return {
    clearMeterStore,
    componentsKpiAggregation,
    componentsKpiAggregationFilled,
    componentsKPIs,
    csvFileTemplateUrl,
    csvFileUploadState,
    dateRange,
    fetchCsvFileTemplateUrl,
    fetchMeterData,
    isLoadingComponentsKpiAggregation,
    meterSummary,
    resetCsvFileUploadState,
    uploadMeterCsvFile,
  }
})

function isKPIAggregationItem (item: KPIAggregationItem[] | KPIResult[]): item is KPIAggregationItem[] {
  if (!item.length) return false

  return 'kpi' in item[0]
}

function getKPIValue (kpiAggregation: KPIAggregationItem[]|KPIResult[], kpiIdentifier: string): Record<'text', number>|undefined {
  let value

  if (isKPIAggregationItem(kpiAggregation)) {
    value = kpiAggregation.find((kpi) => kpi.kpi.identifier === kpiIdentifier)?.kpi.value
  } else {
    value = kpiAggregation.find((kpi) => kpi.identifier === kpiIdentifier)?.value
  }

  return value !== undefined ? { text: value } : undefined
}

function getSubtitleForRow (componentInProject: ComponentInProjectWithContext, currentLanguage: string): string {
  const meterName = componentInProject.component?.[currentLanguage === 'de' ? 'nameDE' : 'nameEN'] ?? ''
  const meterNumber = componentInProject.attributes?.find((attribute) => attribute.key?.includes('NUM'))?.value ?? ''
  const subtitle = [meterName, meterNumber].filter((el) => el !== '').join(', ')
  return subtitle
}

// Show a chip that indicates that the meter is a submeter if the component does not have the attribute CALC_COST=true
function getChipText (attributes: ComponentInProjectAttribute[]): string|undefined {
  return attributes.find((attribute) => attribute.key?.includes('CALC_COST'))?.value === 'True'
    ? undefined
    : i18n.global.t('meter_view.submeter') as string
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMeterStore, import.meta.hot))
}
