import { RouteLocation, RouteLocationNormalized, LocationQuery } from 'vue-router'
import { useAIControlsLogAppsStore } from '@/stores/views/AIControls/Log/apps'
import { RootState } from '@/vuex/types'
import { createNextLocation } from './helpers'
import { Store } from 'vuex'
/**
 * Clears all apps logging values that are displayed in the route query from the store.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function clearAIControlsLogStore (): RouteLocation|void {
  const aiControlsLogAppsStore = useAIControlsLogAppsStore()
  aiControlsLogAppsStore.clear()
}

/**
 * Checks the query of the target route and if it contains values that differ from the stored values it updates the store.
 * @param to Target route with values to be stored.
 */
export function updateAIControlsLogStore (store: Store<RootState>, to: RouteLocationNormalized): RouteLocation|void {
  const aiControlsLogAppsStore = useAIControlsLogAppsStore()
  const value = to.query?.id?.toLocaleString() || null
  if (value !== aiControlsLogAppsStore.state.selectedControlsApp) {
    aiControlsLogAppsStore.state.selectedControlsApp = value
  }
}

/**
 * Checks the store and the query for ai controls app values that should be displayed in the query and adds all that are missing or different.
 * @param store vuex store.
 * @param to Target route with the new query.
 * @returns a new location with updated query, if the query was changed.
 */
export function updateAIControlsLogQuery (store: Store<RootState>, to: RouteLocationNormalized): RouteLocation|void {
  const newQuery: LocationQuery = {}
  const aiControlsLogAppsStore = useAIControlsLogAppsStore()
  if (aiControlsLogAppsStore.state.selectedControlsApp) {
    newQuery.id = aiControlsLogAppsStore.state.selectedControlsApp
  }
  if (Object.keys(newQuery).length > 0) {
    return createNextLocation(to, newQuery)
  }
}
