<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import AlgorithmShortcode from './AlgorithmShortcode.vue'
import { getAlgorithmShortcode } from '@/utils/helpers/controls'
import { AlgorithmActivityCounts } from '@/stores/views/AIControls/algorithms'

interface Props {
  algorithm: AlgorithmActivityCounts
  value: boolean
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (event: 'algorithm-info-dialog-close'): void
}>()

const { t } = useI18n()

const shortcode = computed(() => getAlgorithmShortcode(props.algorithm.algorithm || ''))

const onClose = () => {
  emit('algorithm-info-dialog-close')
}
</script>

<template>
  <v-dialog
    :model-value="value"
    width="480"
    @click:outside="onClose"
    @update:model-value="onClose"
  >
    <v-card
      class="pa-6 algorithm-info-dialog"
      data-testid="algorithm-info-dialog"
    >
      <v-card-title class="tw-flex tw-justify-between tw-items-start tw-gap-2 tw-flex-wrap pa-0 pb-4">
        <AlgorithmShortcode
          :shortcode="shortcode"
          :is-active="false"
          :data-testid="`shortcode`"
          class="test-is-active-false"
          :gradient-nr="8"
        />
        <v-btn
          class="ml-auto remove-hover__btn text-neutral-darken3"
          data-testid="algorithm-info-dialog-close-button"
          icon
          height="24"
          width="24"
          @click="onClose"
        >
          <template #default>
            <v-icon size="14">
              fa:far fa-xmark-large
            </v-icon>
          </template>
        </v-btn>
      </v-card-title>
      <v-card-text class="text-body-2 text-neutral-darken4 pa-0">
        <div
          class="pb-4 tw-text-2xl tw-font-semibold"
          data-testid="algorithm-full-name"
        >
          {{ t(`ai_controls.algorithm.${algorithm.algorithm}.name`) }}
        </div>
        <div
          class="text-neutral-darken3 algorithm-full-description"
          data-testid="algorithm-full-description"
          v-html="t(`ai_controls.algorithm.${algorithm.algorithm}.description`)"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style lang="sass" scoped>
.algorithm-info-dialog
  border: none !important

:deep(.algorithm-full-description)
  p
    &:not(:last-child)
      margin-bottom: 8px !important
</style>
