<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import type { Attribute } from '@/vuex/component_attributes_editor/types'
import { textForLocale } from '@/utils/helpers/locale'
import ComponentAttributeBaseRow from './ComponentAttributeBaseRow.vue'
import ComponentHeader from './ComponentHeader.vue'
import type { ComponentInProjectListItemData } from '@/vuex/components_in_project/types'
import isEqual from 'lodash/isEqual'
import JsonEditorVue from 'json-editor-vue'
import { useI18n } from 'vue-i18n'
import { toValidJSON } from '@/utils/helpers/json'
import { convertToString } from '@/utils/helpers/strings'

interface Props {
  attribute: Attribute
  componentInProject: ComponentInProjectListItemData
}

const props = defineProps<Props>()

const emits = defineEmits<{
  'update:attribute': [ value: Attribute ]
}>()

const { t } = useI18n()

// #region JSON MODAL AND DATA
const jsonData = ref(toValidJSON(props.attribute.value))

const haveValuesChanged = computed(() => {
  return !isEqual(props.attribute.value ? JSON.parse(props.attribute.value) : null, jsonData.value)
})

function handleUpdateAttributeValue () {
  emits('update:attribute', {
    ...props.attribute,
    new_value: convertToString(jsonData.value),
  })

  showJSONModal.value = false
}

const showJSONModal = ref(false)

function handleCloseJSONModal () {
  closeJSONModal()
  resetJSONData()
}

function closeJSONModal () {
  showJSONModal.value = false
}

function resetJSONData () {
  jsonData.value = props.attribute.value ? JSON.parse(props.attribute.value) : {}
}
// #endregion

const attributeName = computed(() => textForLocale(props.attribute.nameDE ?? '', props.attribute.nameEN ?? ''))

watch(() => props.attribute.value, () => {
  jsonData.value = toValidJSON(props.attribute.value)
})
</script>

<template>
  <div>
    <ComponentAttributeBaseRow
      :attribute="attribute"
      readonly
      @update:attribute-value="handleUpdateAttributeValue"
      @click="showJSONModal = true"
    />
    <v-dialog
      v-if="showJSONModal"
      v-model="showJSONModal"
      max-width="600"
      max-height="688"
      persistent
      data-testid="json-modal"
    >
      <v-card>
        <ComponentHeader
          :title="componentInProject.title"
          :description="componentInProject.description"
        />
        <div class="tw-p-6 bg-neutral-lighten3 tw-font-semibold">
          <h5 class="tw-mb-6 text-h5">
            {{ attributeName }}
          </h5>
          <v-sheet class="aedifion-border text-neutral-darken1 tw-h-[450px] tw-overflow-y-auto">
            <JsonEditorVue
              v-model="jsonData"
              mode="tree"
            />
          </v-sheet>
          <div class="tw-p-0 tw-mt-6 tw-flex tw-gap-2">
            <v-btn
              class="tw-bg-white tw-grow"
              color="primary-darken2"
              data-testid="json-dialog-cancel-button"
              variant="outlined"
              @click="handleCloseJSONModal"
            >
              <span class="text-primary-darken2">{{ t('actions.cancel') }}</span>
              <v-icon
                end
                size="medium"
                class="text-primary-darken2"
              >
                fa:far fa-times
              </v-icon>
            </v-btn>
            <v-btn
              class="tw-grow"
              color="primary-darken2"
              data-testid="json-dialog-save-button"
              type="submit"
              :disabled="!haveValuesChanged"
              variant="elevated"
              @click="handleUpdateAttributeValue"
            >
              {{ t('actions.save') }}
              <v-icon
                end
                size="medium"
              >
                fa:far fa-check
              </v-icon>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="sass">
.jse-menu
  background-color: rgb(var(--v-theme-neutral-darken4)) !important
.jse-button:hover:not(.jse-selected)
  background-color: rgb(var(--v-theme-neutral-darken2)) !important
.jse-selected
  color: rgb(var(--v-theme-neutral-darken4)) !important
.jse-contents
  border: 0 !important
.jse-main
  height: 448px !important
</style>
