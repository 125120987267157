<i18n lang="json" locale="de">
{
  "building_certifications": "Gebäudezertifizierungen",
  "equipment": "Ausstattung",
  "general_information": "Allgemeine Informationen",
  "no_building_certifications_message": "Es wurden noch keine Zertifikate hinzugefügt.",
  "stakeholders": "Stakeholders"
}
</i18n>
<i18n lang="json" locale="en">
{
  "building_certifications": "Building certifications",
  "equipment": "Equipment",
  "general_information": "General information",
  "no_building_certifications_message": "No certificates have been added, yet.",
  "stakeholders": "Stakeholders"
}
</i18n>

<template>
  <div class="layout-wrapper">
    <PageHeader
      class="page-header"
      title-key="links.meta.title.asset_profile"
    />
    <div>
      <div
        class="asset-profile__background"
        :style="{ '--avatar_url': `url(${project.avatar_url})` }"
      />
      <div
        class="card__wrapper fill-height"
      >
        <DataCard
          :data="generalInformationData"
          :read-only="isReadOnly"
          data-cy="general-information-card"
          :loading="isLoading || isUpdatingGeneralData"
          :title="t('general_information')"
          @data-card:change="onGeneralDataChanged"
        />
        <DataCard
          :data="stakeholdersData"
          :read-only="isReadOnly"
          data-cy="stakeholders-card"
          :loading="isLoading || isUpdatingStakeholdersData"
          :title="t('stakeholders')"
          @data-card:change="onStakeholdersDataChanged"
        />
        <DataCard
          v-if="hasAnyCertification"
          :data="certificationsData"
          :read-only="isReadOnly"
          data-cy="certifications-card"
          :loading="isLoading || isUpdatingCertificationsData"
          :title="t('building_certifications')"
          @data-card:change="onCertificationsDataChanged"
        />
        <v-card
          v-else
          data-cy="no-certifications-card"
        >
          <v-card-title>
            {{ t("building_certifications") }}
          </v-card-title>
          <v-card-text>
            <span v-html="t('no_building_certifications_message')" />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <AskAedifionDialog>
              <template #button="buttonProps">
                <v-btn
                  v-bind="buttonProps"
                  color="primary"
                >
                  {{ t('support_call_to_action.support') }}
                </v-btn>
              </template>
            </AskAedifionDialog>
          </v-card-actions>
        </v-card>
        <DataCard
          :data="equipmentData"
          :read-only="isReadOnly"
          data-cy="equipment-card"
          :loading="isLoading || isUpdatingEquipmentData"
          :title="t('equipment')"
          @data-card:change="onEquipmentDataChanged"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters, mapState } from 'vuex'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import AskAedifionDialog from '@/components/AskAedifionDialog.vue'
import DataCard from '@/components/DataCard/DataCard.vue'
import { DataRow } from '@/components/DataCard/types'
import { defineComponent } from 'vue'
import PageHeader from '@/components/PageHeader.vue'
import { RootState } from '@/vuex/types'
import { useI18n } from 'vue-i18n'
import useIsReadOnly from '@/composables/useIsReadOnly'

export default defineComponent({
  name: 'AssetOverviewProfile',

  components: {
    AskAedifionDialog,
    DataCard,
    PageHeader,
  },

  async beforeRouteUpdate (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    if (to.params.project && from.params.project && to.params.project !== from.params.project) {
      await this.$store.dispatch('building_analyses/fetchOrCreateBuildingComponentForProject', this.projectId)
    }
    next()
  },

  setup () {
    const { t } = useI18n()
    return { t }
  },

  computed: {
    ...mapGetters({
      certificationsData: 'asset_profile/certificationsData',
      equipmentData: 'asset_profile/equipmentData',
      generalInformationData: 'asset_profile/generalInformationData',
      hasAnyCertification: 'asset_profile/hasAnyCertification',
      project: 'projects/currentProject',
      projectId: 'projects/currentProjectId',
      stakeholdersData: 'asset_profile/stakeholdersData',
    }),

    ...mapState<RootState>({
      isUpdatingCertificationsData: (state: RootState) => state.asset_profile.updatingCertificationsData,
      isUpdatingEquipmentData: (state: RootState) => state.asset_profile.updatingEquipmentData,
      isUpdatingGeneralData: (state: RootState) => state.asset_profile.updatingGeneralData,
      isUpdatingStakeholdersData: (state: RootState) => state.asset_profile.updatingStakeholdersData,
    }),

    isLoading (): boolean {
      return this.$store.getters['building_analyses/isLoadingBuildingComponentOfProject'](this.projectId)
    },

    isReadOnly (): boolean {
      return useIsReadOnly().isReadOnly.value
    },
  },

  async created () {
    await this.$store.dispatch('building_analyses/fetchOrCreateBuildingComponentForProject', this.projectId)
  },

  methods: {
    onCertificationsDataChanged (rows: DataRow[]): void {
      this.$store.dispatch('asset_profile/updateCertificationsData', rows)
    },

    onEquipmentDataChanged (rows: DataRow[]): void {
      this.$store.dispatch('asset_profile/updateEquipmentData', rows)
    },

    onGeneralDataChanged (rows: DataRow[]): void {
      this.$store.dispatch('asset_profile/updateGeneralData', rows)
    },

    onStakeholdersDataChanged (rows: DataRow[]): void {
      this.$store.dispatch('asset_profile/updateStakeholdersData', rows)
    },
  },
})
</script>

<style lang="sass" scoped>
.page-header
  position: relative
  z-index: 1

.card__wrapper
  display: flex
  flex-direction: column
  margin: auto
  gap: 10px
  max-width: 600px

.asset-profile__background
  position: fixed
  top: 0
  left: 0
  width: calc(100vw - 200px)
  height: 100vh
  margin-left: 200px
  background: linear-gradient(180deg, rgba(241, 241, 241, 0.5) 0%, #F1F1F1 100%), var(--avatar_url)
  background-size: cover
  background-repeat: no-repeat
  background-position: center
</style>
