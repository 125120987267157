<script setup lang="ts">
import { AnalogSeriesOptions, AnalogSeriesOptionsWithYAxis, BinarySeriesOptions, SeriesOptions } from './TimeseriesViewer.types'
import { computed, ref } from 'vue'
import Highcharts from 'highcharts'
import LineChart from '@/components/Charts/LineChart.vue'
import { mapLineChartSeriesToXRange } from '@/filters/highcharts'
import { useI18n } from 'vue-i18n'
import { VUETIFY_COLORS } from '@theme/colors'
import XRangeChart from '@/components/Charts/XRangeChart.vue'
import { ZoomParameters } from './types'

// if the maximum currently displayed value of a timeseries is at least [this
// constant] times smaller than the maximum currently displayed value across all
// timeseries, then the timeseries uses the second Y axis
const DELTA_TIMESERIES_MAX_THRESHOLD = 3

interface Props {
  customLineChartOptions?: Highcharts.ChartOptions,
  customXRangeChartOptions?: Highcharts.ChartOptions,
  isZoomed?: boolean,
  loading?: boolean,
  series: SeriesOptions[],
  xMax: Date,
  xMin: Date
}

// --- definition ---

const props = withDefaults(defineProps<Props>(), {
  customLineChartOptions: () => ({}),
  customXRangeChartOptions: () => ({}),
  isZoomed: false,
  loading: false,
})

const emit = defineEmits<{
  (e: 'timeseries-viewer:reset-zoom'): void;
  (e: 'timeseries-viewer:zoom', params: ZoomParameters): void;
}>()

const { t } = useI18n()

const binarySeries = computed(() => {
  return props.series.filter((seriesElement): seriesElement is BinarySeriesOptions => {
    return seriesElement.custom.isBinary === true && seriesElement.visible
  })
    .reverse()
    .map(mapLineChartSeriesToXRange)
})
const analogSeries = computed(() => {
  return props.series.filter((seriesElement): seriesElement is AnalogSeriesOptions => {
    return seriesElement.custom.isBinary === false && seriesElement.visible
  })
})

const analogSeriesWithMax = computed(() => {
  return analogSeries.value.map((serie) => {
    return { ...serie, custom: { ...serie.custom, maxValue: Math.max(...serie.data.map((observation) => Number(observation[1]))) } }
  })
})

const analogSeriesWithYAxis = computed(() => {
  const axisChoiceReferenceValue = Math.max(...analogSeriesWithMax.value.map((serie) => serie.custom.maxValue)) / DELTA_TIMESERIES_MAX_THRESHOLD

  return analogSeriesWithMax.value.map((serie) => {
    return { ...serie, yAxis: serie.custom.maxValue > axisChoiceReferenceValue ? 0 : 1 } as AnalogSeriesOptionsWithYAxis
  })
})

const shouldAnalogChartHaveSecondYAxis = computed(() => {
  return analogSeriesWithYAxis.value.some((serie) => serie.yAxis >= 1)
})

const shouldBinaryChartHaveTicks = computed(() => {
  return !analogSeries.value.length && !!binarySeries.value.length
})

const analogCursorPosition = ref<number | null>(null)
const binaryCursorPosition = ref<{x1: number, x2: number} | null>(null)

function setAnalogCursorPosition (value: number|null) {
  analogCursorPosition.value = value
}

function setBinaryCursorPosition (value: { x1: number, x2: number }|null) {
  binaryCursorPosition.value = value
}

const plotLines = computed(() => {
  if (analogCursorPosition.value === null) {
    return []
  }
  return [{
    color: VUETIFY_COLORS.neutral.base,
    value: analogCursorPosition.value,
    width: 1,
    zIndex: 2,
  }]
})

const plotBands = computed(() => {
  if (binaryCursorPosition.value === null) {
    return []
  }
  return [{
    color: VUETIFY_COLORS.neutral.lighten3,
    from: binaryCursorPosition.value.x1,
    to: binaryCursorPosition.value.x2,
  }]
})

function resetZoom () {
  emit('timeseries-viewer:reset-zoom')
}

function zoom (zoomParameters: ZoomParameters) {
  emit('timeseries-viewer:zoom', zoomParameters)
}
</script>

<template>
  <div class="timeseries-viewer">
    <div class="d-flex justify-end mb-2">
      <v-btn
        :disabled="!props.isZoomed"
        @click="resetZoom"
      >
        {{ t('highcharts.reset_zoom') }}
      </v-btn>
    </div>
    <XRangeChart
      v-if="binarySeries.length"
      :custom-options="props.customXRangeChartOptions"
      :plot-bands="plotBands"
      :plot-lines="plotLines"
      :series="binarySeries"
      :should-have-margin-for-right-y-axis="shouldAnalogChartHaveSecondYAxis"
      :should-have-ticks="shouldBinaryChartHaveTicks"
      :x-max="props.xMax"
      :x-min="props.xMin"
      @x-range-chart:reset-zoom="resetZoom"
      @x-range-chart:zoom="zoom($event)"
      @x-range-chart:reset-cursor="setBinaryCursorPosition(null)"
      @x-range-chart:set-cursor="setBinaryCursorPosition($event)"
    />
    <LineChart
      v-show="!binarySeries.length || analogSeries.length"
      :custom-options="props.customLineChartOptions"
      :loading="props.loading"
      :plot-bands="plotBands"
      :plot-lines="plotLines"
      :series="analogSeriesWithYAxis"
      :should-have-second-y-axis="shouldAnalogChartHaveSecondYAxis"
      :x-max="props.xMax"
      :x-min="props.xMin"
      @line-chart:reset-zoom="resetZoom"
      @line-chart:zoom="zoom($event)"
      @line-chart:reset-cursor="setAnalogCursorPosition(null)"
      @line-chart:set-cursor="setAnalogCursorPosition($event)"
    />
  </div>
</template>
