import { PiniaPluginContext } from 'pinia'
import { watch } from 'vue'
import router from '@/router'

export function queryUpdater ({ store }: PiniaPluginContext) {
  if (store.$id === 'AIControlsLogApps') {
    watch(() => store.$state.state?.selectedControlsApp, (newValue, oldValue) => {
      if (router.currentRoute?.value?.name === 'ai-controls-log') {
        if (newValue && oldValue !== newValue) {
          router.push({
            query: { id: newValue },
          })
        }
      }
    })
  }
}
