/* eslint-disable @typescript-eslint/no-explicit-any */
import { aedifionApi, LabelsSystems } from '@aedifion.io/aedifion-api'
import { readLabels, storeLabels } from '@/services/database/labels'
import { ActionTree } from 'vuex'
import i18n from '@/i18n'
import { LabelsState } from './types'
import { reportError } from '@/utils/helpers/errors'
import { RootState } from '../types'
import { showErrorNotification } from '@/utils/helpers/notifications'

export default {
  /**
   * Requests label definitions from the API and stores them in the IndexedDB.
   * WARNING: This action should not be called manually because it will circumvent the store that
   * already holds the label definitions. Calling this action manually is unnecessary.
   * @param checksum The checksum of the label definitions.
   */
  fetchLabelDefinitions: async ({ commit, state }, locale: string): Promise<void> => {
    if (state.systems === null) return
    let labelDefinitions: any|null = null
    try {
      labelDefinitions = await readLabels(state.systems.labels_checksum!, locale)
      if (labelDefinitions !== null) {
        commit('SET_DEFINITIONS', labelDefinitions)
        return
      }
    } catch (error) {
      reportError(error)
    }

    state.loadingDefinitions = true
    const hasUsersLocale: boolean = state.systems?.locales?.some((systemsLocale: string) => systemsLocale.includes(locale)) ?? false
    const usedLocale = hasUsersLocale ? locale : 'en'
    try {
      labelDefinitions = await aedifionApi.Meta.getLabelsDefinitions(usedLocale)
      commit('SET_DEFINITIONS', labelDefinitions)
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.label_definitions') })}`)
      reportError(error)
    } finally {
      state.loadingDefinitions = false
    }

    if (labelDefinitions !== null) {
      try {
        storeLabels(state.systems!.labels_checksum!, usedLocale, labelDefinitions)
      } catch (error) {
        reportError(error)
      }
    }
  },

  fetchLabelSystems: async ({ commit, dispatch, state }): Promise<void> => {
    state.loadingSystems = true
    let labelSystems: LabelsSystems|null = null

    try {
      labelSystems = await aedifionApi.Meta.getLabelsSystems()
      commit('SET_SYSTEMS', labelSystems)
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.label_systems') })}`)
      reportError(error)
    } finally {
      state.loadingSystems = false
    }

    if (labelSystems !== null) {
      await dispatch('fetchLabelDefinitions', i18n.global.locale.value)
    }
  },
} as ActionTree<LabelsState, RootState>
