import { aedifionApi, AnalyticsResultsForComponent, ComponentInProjectWithContext, User } from '@aedifion.io/aedifion-api'
import { ActionTree } from 'vuex'
import i18n from '@/i18n'
import { OptimizationState } from './types'
import { reportError } from '@/utils/helpers/errors'
import { resetStoreState } from './state'
import { RootState } from '../types'
import { showErrorNotification } from '@/utils/helpers/notifications'
import { useUserStore } from '@/stores/user'
import { validateNotNullish } from '@/utils/helpers/validate'

function clearComponentSpecificData (rootState: RootState) {
  rootState.analysis_instances.analysisResult = null
  rootState.optimization.selectedInstanceId = null
  rootState.optimization.selectedResultId = null
}

export default {
  clear: ({ state }) => {
    resetStoreState(state)
  },

  ensureAnyComponentInProjectIsSelected: async ({ dispatch, getters, rootState, rootGetters }) => {
    if (!getters.getSelectedComponent) {
      const firstComponentInProjectId = rootGetters['components_in_project/firstComponentInProjectId'] as number|null
      if (firstComponentInProjectId) {
        await dispatch('selectComponentInProject', firstComponentInProjectId)
      } else {
        clearComponentSpecificData(rootState)
      }
    }
  },

  fetchComponentsAnalysisResults: async ({ commit, getters, state, rootGetters }) => {
    state.loadingComponentsAnalysisResults = true

    try {
      const projectID = validateNotNullish(
        rootGetters['projects/currentProjectId'] as number|null,
        { errorMessage: i18n.global.t('notifications.errors.no_project_selected') as string },
      )
      const componentInProjectID = validateNotNullish((getters.getSelectedComponent as ComponentInProjectWithContext|null)?.id)

      const userStore = useUserStore()
      const user: Readonly<User> = validateNotNullish(userStore.userDetails)
      const componentInProjectResultsApiResponse: AnalyticsResultsForComponent = await aedifionApi.Analytics.getComponentResults(
        componentInProjectID,
        projectID,
        i18n.global.locale.value,
        user.units_system,
        user.currency_system,
      )
      commit('SET_SELECTED_COMPONENTS_ANALYSIS_RESULTS', componentInProjectResultsApiResponse)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.status !== 400) {
        showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.analysis_results') })}`)
        reportError(error)
        commit('SET_SELECTED_COMPONENTS_ANALYSIS_RESULTS', null)
      } else {
        commit('SET_SELECTED_COMPONENTS_ANALYSIS_RESULTS', [])
      }
    } finally {
      state.loadingComponentsAnalysisResults = false
    }
  },

  fetchFullComponentInProject: async ({ commit, rootGetters }, payload: {
    componentInProjectID: number;
    notifications?: boolean;
  }) => {
    commit('SET_LOADING_COMPONENT_IN_PROJECT', true)
    try {
      const projectId = validateNotNullish(
        rootGetters['projects/currentProjectId'] as number|null,
        { errorMessage: i18n.global.t('notifications.errors.no_project_selected') as string },
      )
      const componentInProjectApiResponse: ComponentInProjectWithContext = await aedifionApi.Project.getProjectComponent(
        projectId,
        payload.componentInProjectID,
      )
      commit('SET_SELECTED_COMPONENT_IN_PROJECT', componentInProjectApiResponse)
    } catch (error) {
      if (payload.notifications) {
        showErrorNotification(`${i18n.global.t('notifications.errors.componentInProject.fetch')}`)
      }
      reportError(error)
    } finally {
      commit('SET_LOADING_COMPONENT_IN_PROJECT', false)
    }
  },

  selectComponentInProject: async ({ dispatch, getters, rootState }, componentInProjectID: number) => {
    const alreadySelectedComponent = getters.getSelectedComponent as ComponentInProjectWithContext|null
    if (alreadySelectedComponent === null || alreadySelectedComponent.id !== componentInProjectID) {
      await dispatch('fetchFullComponentInProject', { componentInProjectID })
      const newlySelectedComponent = validateNotNullish(
        getters.getSelectedComponent as ComponentInProjectWithContext|null,
        { errorMessage: i18n.global.t('notifications.errors.no_component_in_project') as string },
      )

      dispatch('analysis_functions/fetchAnalysisFunctions', {
        component_id: newlySelectedComponent.component?.id,
      }, { root: true })
      dispatch('fetchComponentsAnalysisResults')
      clearComponentSpecificData(rootState)
    }
  },

  unselectComponentInProject: ({ commit, rootState }) => {
    commit('SET_SELECTED_COMPONENT_IN_PROJECT', null)
    clearComponentSpecificData(rootState)
  },
} as ActionTree<OptimizationState, RootState>
