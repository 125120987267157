/**
 * Converts the input value to a string representation.
 *
 * @param input - The value to be converted.
 * @returns The string representation of the input value.
 */
export function convertToString (input: unknown) {
  if (input === '' || input === null || input === undefined || typeof input === 'function') {
    return ''
  }

  if (typeof input === 'string') {
    try {
      const parsedJSON = JSON.parse(input)
      // Stringify it again to clean up formatting
      return JSON.stringify(parsedJSON)
    } catch (error) {
      return input
    }
  }

  if (typeof input === 'object' && input !== null) {
    try {
      return JSON.stringify(input)
    } catch (error) {
      // If stringification fails (e.g., circular reference), return a fallback string
      return ''
    }
  }

  // For all other types (number, boolean, symbol)
  return String(input)
}
