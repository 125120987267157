import { aedifionApi, AnalysisFunction } from '@aedifion.io/aedifion-api'
import { AnalysisFunctionState, FetchAnalysisFunctionsPayload } from './types'
import { ActionTree } from 'vuex'
import i18n from '@/i18n'
import { reportError } from '@/utils/helpers/errors'
import { resetStoreState } from './state'
import { RootState } from '../types'
import { showErrorNotification } from '@/utils/helpers/notifications'

export default {
  clear: ({ state }) => {
    resetStoreState(state)
  },

  fetchAnalysisFunctions: async ({ commit }, payload: FetchAnalysisFunctionsPayload) => {
    commit('SET_LOADING_ANALYSIS_FUNCTIONS', true)
    try {
      const functionsApiResponse: AnalysisFunction[] = await aedifionApi.Analytics.getAnalysisFunctions(undefined, payload.component_id)
      commit('SET_ANALYSIS_FUNCTIONS', functionsApiResponse)
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.functions.fetch')}`)
      reportError(error)
    } finally {
      commit('SET_LOADING_ANALYSIS_FUNCTIONS', false)
    }
  },
} as ActionTree<AnalysisFunctionState, RootState>
