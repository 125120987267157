<template>
  <div
    :class="{ 'item__box--expanded': isExpanded }"
  >
    <div class="item__box__background rounded" />
    <router-link
      v-slot="{ navigate }"
      :to="to ?? {}"
      :custom="to ? false : true"
    >
      <div
        :class="['item', { 'item--active': isActive }]"
        role="link"
        @click="to ? navigate() : $emit('click', $event)"
      >
        <div class="item__background rounded" />
        <slot name="avatar" />
        <v-icon
          v-if="icon"
          class="item__icon"
          :color="isActive ? 'primary-darken2' : 'neutral'"
          size="14"
        >
          {{ icon }}
        </v-icon>
        <span
          :class="[
            'item__title text-body-1 font-weight-medium text-truncate',
            {
              'text-primary-darken2': isActive,
              'text-neutral-lighten3': !isActive
            }
          ]"
          v-text="title"
        />
      </div>
    </router-link>

    <v-expand-transition>
      <div v-show="isExpanded">
        <NavigationItem
          v-for="(child, index) in children"
          :key="`project_item_child_${index}`"
          class="item--child"
          :route-names="child.routeNames"
          :title="child.title"
          :to="child.to"
        />
      </div>
    </v-expand-transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { NavigationChildItemData } from './types'
import { RouteLocation } from 'vue-router'

export default defineComponent({
  name: 'NavigationItem',

  props: {
    active: {
      required: false,
      type: Boolean as PropType<boolean>,
    },

    children: {
      required: false,
      type: Array as PropType<Array<NavigationChildItemData>>,
    },

    icon: {
      required: false,
      type: String as PropType<string>,
    },

    routeNames: {
      required: false,
      type: Array as PropType<Array<string>>,
    },

    title: {
      required: true,
      type: String as PropType<string>,
    },

    to: {
      required: false,
      type: Object as PropType<RouteLocation>,
    },
  },

  emits: ['click'],

  computed: {
    isActive (): boolean {
      return this.active || (!!this.routeNames && this.routeNames.includes(this.$route.name! as string))
    },

    isExpanded (): boolean {
      if (!this.children) {
        return false
      } else if (this.isActive) {
        return true
      } else {
        return this.children.some((child: NavigationChildItemData) => {
          return child.routeNames && child.routeNames.includes(this.$route.name! as string)
        })
      }
    },
  },
})
</script>

<style lang="sass" scoped>
.item__background, .item__box__background
  pointer-events: none
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  background-color: rgb(var(--v-theme-neutral-lighten2))
  opacity: 0

.item__box--expanded
  position: relative
  > .item__box__background
    opacity: .2

.item
  display: flex
  align-items: center
  position: relative
  cursor: pointer
  padding: 10px
  text-decoration: none
  user-select: none
  &:hover .item__background
    opacity: .1
  &.item--active
    .item__background
      background-color: rgb(var(--v-theme-primary-lighten3))
      opacity: 1

.item--child
  .item__title
    margin-left: 24px
    font-feature-settings: 'cv08' on

.item__icon
  margin-right: 10px
  height: 20px
  width: 20px

.item__title
  z-index: 1
  height: 20px
  font-feature-settings: 'cv08' on
</style>
