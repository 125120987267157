
import { ref } from 'vue'
import { defineStore } from 'pinia'
import i18n from '@/i18n'
import { ControlsAppStatus, useControlsApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import { reportError } from '@/utils/helpers/errors'
import { showErrorNotification } from '@/utils/helpers/notifications'
import { getControlsAppsPayload, getStatusCodeMeaning, NOT_HIDDEN_INACTIVE_STATUS_CODES, NOT_HIDDEN_STATUS_CODES, reportGetControlsAppsError } from '@/utils/helpers/controls'
import { useAppStore } from '@/stores/app'
import { ControlsAppList } from '@aedifion.io/aedifion-api'

interface AppsActivityCounts {
  active: number
  inactive: number
}

export interface AlgorithmActivityCounts {
  algorithm: string
  appsActivityCounts: AppsActivityCounts
  sampleAppId?: string
}

const APP_ACTIVITY_STATES: Record<keyof AppsActivityCounts, ControlsAppStatus[]> = {
  active: NOT_HIDDEN_STATUS_CODES.filter((code) => getStatusCodeMeaning('active', code)),
  inactive: NOT_HIDDEN_INACTIVE_STATUS_CODES,
}

export const useAIControlsAlgorithmsStore = defineStore('AIControlsAlgorithms', () => {
  const appStore = useAppStore()
  const controlsApiStore = useControlsApiStore()

  const algorithms = ref<Array<AlgorithmActivityCounts>|undefined>()

  function clear (): void {
    algorithms.value = algorithms.value?.map((algorithm) => ({ algorithm: algorithm.algorithm, appsActivityCounts: { active: 0, inactive: 0 } }))
  }

  async function fetchAlgorithms (): Promise<void> {
    if (algorithms.value) { // fetch them only once, since they don't change
      return
    }
    try {
      // collect all available algorithms
      const components = await controlsApiStore.getControlsAlgorithmsByComponent({})
      const allAlgorithms = new Set<string>()
      Object.values(components).forEach((component) => {
        Object.keys(component as Record<string, unknown>).forEach((algorithm) => allAlgorithms.add(algorithm))
      })
      algorithms.value = []
      allAlgorithms.forEach((algorithm) => algorithms.value?.push({ algorithm, appsActivityCounts: { active: 0, inactive: 0 } }))
    } catch (error) {
      reportError(error)
      showErrorNotification(i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.controls_apps_algorithms') }))
    }
  }

  async function fetchAlgorithmsActiveAppsCounts (): Promise<void> {
    clear()
    await fetchAlgorithms()
    // fetch app activities for each algorithm in project
    const algorithmAppsRequests: Promise<AlgorithmActivityCounts>[] = []
    algorithms.value?.forEach(async (algorithm) => {
      algorithmAppsRequests.push(_fetchAlgorithmActiveAppsCounts(algorithm.algorithm))
    })
    const appsActivityCounts = await Promise.all(algorithmAppsRequests)
    appsActivityCounts.forEach((response) => {
      const algorithm = algorithms.value?.find((algorithm) => algorithm.algorithm === response.algorithm)
      if (!algorithm) {
        return
      }
      algorithm.appsActivityCounts = response.appsActivityCounts
      algorithm.sampleAppId = response.sampleAppId
    })

    // sort algorithms by active apps count descending (when same than inactive apps count descending)
    algorithms.value?.sort((a, b) => {
      if (a.appsActivityCounts.active > b.appsActivityCounts.active) {
        return -1
      } else if (a.appsActivityCounts.active < b.appsActivityCounts.active) {
        return 1
      } else if (a.appsActivityCounts.inactive > b.appsActivityCounts.inactive) {
        return -1
      } else if (a.appsActivityCounts.inactive < b.appsActivityCounts.inactive) {
        return 1
      } else {
        return 0
      }
    })
  }

  async function _fetchAlgorithmActiveAppsCounts (algorithm: string): Promise<AlgorithmActivityCounts> {
    const controlsAppsRequests: Promise<ControlsAppList|undefined>[] = []
    Object.keys(APP_ACTIVITY_STATES).forEach((state) => {
      controlsAppsRequests.push(controlsApiStore.getControlsApps(getControlsAppsPayload(appStore.projectId, APP_ACTIVITY_STATES[state as keyof AppsActivityCounts], algorithm)))
    })
    const returnObject: AlgorithmActivityCounts = { algorithm, appsActivityCounts: { active: 0, inactive: 0 } }
    try {
      const responses = await Promise.all(controlsAppsRequests)
      if (responses && responses[0] && responses[1]) {
        returnObject.appsActivityCounts = {
          active: responses[0].meta.total_items ?? 0,
          inactive: responses[1].meta.total_items ?? 0,
        }
        returnObject.sampleAppId = responses[0].items[0]?.id || responses[1].items[0]?.id
      }
    } catch (error) {
      reportGetControlsAppsError(error as Error)
    }
    return returnObject
  }

  return {
    algorithms,
    clear,
    fetchAlgorithms,
    fetchAlgorithmsActiveAppsCounts,
  }
})
