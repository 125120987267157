<i18n locale="de">
{
  "about": "Über",
  "ask_support_team": "Benötigst du Unterstützung bei der Hebung der realisierbaren Optimierungen? Frage das Support Team."
}
</i18n>
<i18n locale="en">
{
  "about": "About",
  "ask_support_team": "Do you need support in raising the realizable optimizations? Ask the support team."
}
</i18n>

<template>
  <v-card
    class="d-flex flex-column"
    rounded
  >
    <v-card-title>
      <v-skeleton-loader
        v-if="loading"
        class="mt-2"
        height="22"
        :loading="loading"
        min-width="75%"
        type="text"
      />
      <div v-else>
        {{ t('about') }} <span v-html="formatSubAndSuper(KPIName)" />
      </div>
    </v-card-title>
    <v-card-text class="overflow-auto pb-0">
      <div
        v-if="loading"
        class="mb-3 mt-2"
      >
        <v-skeleton-loader
          height="15"
          :loading="loading"
          type="text"
        />
        <v-skeleton-loader
          height="15"
          :loading="loading"
          type="text"
        />
        <v-skeleton-loader
          height="15"
          :loading="loading"
          type="text"
        />
        <v-skeleton-loader
          height="15"
          :loading="loading"
          type="text"
        />
      </div>
      <ul
        v-else-if="analysisResult && analysisResult.recommendation"
        class="recommendations pl-4"
      >
        <li
          v-for="recommendation, i in analysisResult.recommendation"
          :key="i"
          v-html="formatSubAndSuper(recommendation)"
        />
      </ul>
    </v-card-text>
    <v-card-actions class="pt-0 px-4 pb-4 mt-10">
      <AskAedifionDialog
        :message="t('ask_support_team')"
      >
        <template #button="buttonProps">
          <v-btn
            v-bind="buttonProps"
            class="text-primary-darken2"
            color="primary-lighten3"
            variant="elevated"
          >
            {{ t('support_call_to_action.support') }}
          </v-btn>
        </template>
      </AskAedifionDialog>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { AnalysisResult } from '@aedifion.io/aedifion-api'
import AskAedifionDialog from '@/components/AskAedifionDialog.vue'
import { formatSubAndSuper } from '@/filters/formatting'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'AssetAnalysisRecommendations',

  components: {
    AskAedifionDialog,
  },

  props: {
    analysisResult: {
      default: null,
      required: false,
      type: Object as PropType<AnalysisResult>,
    },

    kpiKey: {
      required: true,
      type: String as PropType<string>,
    },

    loading: {
      default: false,
      required: false,
      type: Boolean as PropType<boolean>,
    },
  },

  setup () {
    const { t, te } = useI18n()
    return { t, te }
  },

  computed: {
    KPIName (): string {
      if (this.analysisResult && this.analysisResult.display_name) {
        return this.analysisResult.display_name
      } else if (this.te(`analysis_titles.${this.kpiKey}`)) {
        return this.t(`analysis_titles.${this.kpiKey}`) as string
      } else {
        return this.t('analysis_titles.fallback_title') as string
      }
    },
  },

  methods: {
    formatSubAndSuper,
  },
})
</script>

<style lang="sass" scoped>
  .recommendations li:not(:first-child)
    margin-top: 4px
</style>
