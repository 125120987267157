import { ComponentInProjectWithContext, KPIAggregationResult, ProjectKPIAggregationResultItem, ProjectWithContext, User } from '@aedifion.io/aedifion-api'
import { computed, ref } from 'vue'
import { GetKpiAggregationPayload, useAnalyticsApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import { defineStore } from 'pinia'
import { getComponentAttribute } from '@/utils/helpers/componentAttribute'
import i18n from '@/i18n'
import moment from 'moment'
import { reportError } from '@/utils/helpers/errors'
import { showErrorNotification } from '@/utils/helpers/notifications'
import { useUserStore } from '../user'
import { validateNotNullish } from '@/utils/helpers/validate'
import vuexStore from '@/vuex'

export type EnergyAndCO2ChartProject = {
  address?: string;
  co2KpiValue: number;
  energyKpiValue: number;
  name: string;
  netFloorArea?: string;
  type?: string;
}

const findAggregationForProjectId = (aggregation: KPIAggregationResult, projectId: number): ProjectKPIAggregationResultItem | undefined => {
  return aggregation?.project_results?.find((project) => {
    return project.project.id === projectId
  })
}

const hasKpiValue = (kpiAggregation?: ProjectKPIAggregationResultItem) => {
  return typeof kpiAggregation?.aggregation_value === 'number'
}

export const useEnergyAndCO2Store = defineStore('energyAndCO2', () => {
  const analyticsApiStore = useAnalyticsApiStore()
  const userStore = useUserStore()

  const co2KpiAggregation = ref<KPIAggregationResult|null>(null)
  const co2KpiAbsoluteAggregation = ref<KPIAggregationResult|null>(null)
  const energyKpiAggregation = ref<KPIAggregationResult|null>(null)
  const energyKpiAbsoluteAggregation = ref<KPIAggregationResult|null>(null)
  const loading = ref(false)

  const projectsWithKpiValuesAndNoBuildingComponent = computed<number[]>(() => {
    const projectIds: number[] = []

    if (co2KpiAggregation.value?.project_results && energyKpiAggregation.value?.project_results) {
      for (let i = 0; i < co2KpiAggregation.value.project_results.length; i++) {
        const co2ProjectAggregation = co2KpiAggregation.value.project_results[i]
        const currentProjectId = co2ProjectAggregation.project.id
        const energyProjectAggregation = findAggregationForProjectId(energyKpiAggregation.value, currentProjectId!)

        if (
          hasKpiValue(co2ProjectAggregation) &&
          hasKpiValue(energyProjectAggregation) &&
          vuexStore.getters['building_analyses/buildingComponentOfProject'](currentProjectId) === null
        ) {
          projectIds.push(currentProjectId!)
        }
      }
    }

    if (co2KpiAbsoluteAggregation.value?.project_results && energyKpiAbsoluteAggregation.value?.project_results) {
      for (let i = 0; i < co2KpiAbsoluteAggregation.value.project_results.length; i++) {
        const co2AbsoluteProjectAggregation = co2KpiAbsoluteAggregation.value.project_results[i]
        const currentProjectId = co2AbsoluteProjectAggregation.project.id
        const energyAbsoluteProjectAggregation = findAggregationForProjectId(energyKpiAbsoluteAggregation.value, currentProjectId!)

        if (
          !projectIds.includes(currentProjectId!) &&
          hasKpiValue(co2AbsoluteProjectAggregation) &&
          hasKpiValue(energyAbsoluteProjectAggregation) &&
          vuexStore.getters['building_analyses/buildingComponentOfProject'](currentProjectId) === null
        ) {
          projectIds.push(currentProjectId!)
        }
      }
    }

    return projectIds
  })

  async function fetchKpiAggregations () {
    loading.value = true
    try {
      const user: Readonly<User> = validateNotNullish(userStore.userDetails)
      const projectIds = vuexStore.getters['projects/projectIds'] as number[]

      // start to end corresponds to the last complete 12 months
      const start = moment().utc().subtract(1, 'year').startOf('month').toDate()
      const end = moment().utc().startOf('month').toDate()

      const commonParams: Partial<GetKpiAggregationPayload> = {
        currencySystem: user.currency_system,
        end,
        projectAggregation: 'sum',
        projectIds,
        start,
        timeAggregation: 'sum',
        unitsSystem: 'metric',
      }
      const co2Payload = {
        ...commonParams,
        kpi: 'co2_emissions',
      } as GetKpiAggregationPayload
      const co2AbsolutePayload = {
        ...commonParams,
        kpi: 'co2_emissions_absolute',
      } as GetKpiAggregationPayload
      const energyPayload = {
        ...commonParams,
        kpi: 'energy_consumption',
      } as GetKpiAggregationPayload
      const energyAbsolutePayload = {
        ...commonParams,
        kpi: 'energy_consumption_absolute',
      } as GetKpiAggregationPayload

      const kpiRequests: Promise<KPIAggregationResult>[] = []
      kpiRequests.push(analyticsApiStore.getKpiAggregation(co2Payload, { storeResult: false }))
      kpiRequests.push(analyticsApiStore.getKpiAggregation(co2AbsolutePayload, { storeResult: false }))
      kpiRequests.push(analyticsApiStore.getKpiAggregation(energyPayload, { storeResult: false }))
      kpiRequests.push(analyticsApiStore.getKpiAggregation(energyAbsolutePayload, { storeResult: false }))

      const [
        co2KpiResponse,
        co2KpiAbsoluteResponse,
        energyKpiResponse,
        energyKpiAbsoluteResponse,
      ] = await Promise.all(kpiRequests)
      co2KpiAggregation.value = co2KpiResponse
      co2KpiAbsoluteAggregation.value = co2KpiAbsoluteResponse
      energyKpiAggregation.value = energyKpiResponse
      energyKpiAbsoluteAggregation.value = energyKpiAbsoluteResponse

      const loadBuildingComponents = projectsWithKpiValuesAndNoBuildingComponent.value.map((projectId) => {
        // the building components need to be populated for the net floor area and
        // building type attributes to be available
        return vuexStore.dispatch('building_analyses/fetchOrCreateBuildingComponentForProject', projectId)
      })
      await Promise.all(loadBuildingComponents)
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.projects.loadProjects')}`)
      reportError(error)
    } finally {
      loading.value = false
    }
  }

  const chartProjects = computed<EnergyAndCO2ChartProject[]>(() => {
    const results: EnergyAndCO2ChartProject[] = []

    if (co2KpiAggregation.value?.project_results) {
      for (const co2ProjectAggregation of co2KpiAggregation.value.project_results) {
        const energyProjectAggregation = energyKpiAggregation.value?.project_results?.find((energyProject) => {
          return energyProject.project.id === co2ProjectAggregation.project.id
        })

        if (hasKpiValue(co2ProjectAggregation) && hasKpiValue(energyProjectAggregation)) {
          const projectWithContext = vuexStore.getters['projects/project'](co2ProjectAggregation.project.id) as ProjectWithContext|null
          const buildingComponent = vuexStore.getters['building_analyses/buildingComponentOfProject'](co2ProjectAggregation.project.id) as ComponentInProjectWithContext|null

          if (projectWithContext && projectWithContext.project) {
            results.push({
              address: projectWithContext.project.address,
              co2KpiValue: co2ProjectAggregation.aggregation_value!,
              energyKpiValue: energyProjectAggregation!.aggregation_value!,
              name: projectWithContext.project.name,
              netFloorArea: getComponentAttribute(buildingComponent, 'B+NFA')?.value,
              type: getComponentAttribute(buildingComponent, 'B+TYP')?.value,
            })
          }
        }
      }
    }

    return results
  })

  const portfolioNetFloorArea = computed(() => {
    let totalNetFloorArea = 0

    if (co2KpiAbsoluteAggregation.value?.project_results) {
      for (const co2AbsoluteProjectAggregation of co2KpiAbsoluteAggregation.value.project_results) {
        if (hasKpiValue(co2AbsoluteProjectAggregation)) {
          const buildingComponent = vuexStore.getters['building_analyses/buildingComponentOfProject'](co2AbsoluteProjectAggregation.project.id) as ComponentInProjectWithContext|null
          const netFloorArea = Number(getComponentAttribute(buildingComponent, 'B+NFA')?.value)

          if (netFloorArea) {
            totalNetFloorArea += netFloorArea
          }
        }
      }
    }

    return totalNetFloorArea
  })

  const co2KpiUnit = computed(() => {
    return vuexStore.getters['labels/label']('units', co2KpiAggregation.value?.units)
  })

  const co2KpiAbsoluteUnit = computed(() => {
    return vuexStore.getters['labels/label']('units', co2KpiAbsoluteAggregation.value?.units)
  })

  const energyKpiUnit = computed(() => {
    return vuexStore.getters['labels/label']('units', energyKpiAggregation.value?.units)
  })

  const energyKpiAbsoluteUnit = computed(() => {
    return vuexStore.getters['labels/label']('units', energyKpiAbsoluteAggregation.value?.units)
  })

  return {
    chartProjects,
    co2KpiAbsoluteAggregation,
    co2KpiAbsoluteUnit,
    co2KpiAggregation,
    co2KpiUnit,
    energyKpiAbsoluteAggregation,
    energyKpiAbsoluteUnit,
    energyKpiAggregation,
    energyKpiUnit,
    fetchKpiAggregations,
    loading,
    portfolioNetFloorArea,
    projectsWithKpiValuesAndNoBuildingComponent,
  }
})
