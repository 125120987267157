import { aedifionApi, Project, ProjectWithContext, UpdateProject } from '@aedifion.io/aedifion-api'
import { ProjectPayload, ProjectsState } from './types'
import { ActionTree } from 'vuex'
import i18n from '@/i18n'
import { reportError } from '@/utils/helpers/errors'
import { resetStoreState } from './state'
import { RootState } from '../types'
import { showErrorNotification } from '@/utils/helpers/notifications'
import { validateNotEqual } from '@/utils/helpers/validate'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const storeContainsProject = (getters: any, projectId: number) => {
  return (getters.projects as ProjectWithContext[]).some((project) => {
    return project.project?.id === projectId
  })
}

export default {
  clear: ({ state }) => {
    resetStoreState(state)
  },

  ensureProjectSelected: ({ commit, getters }) => {
    if (!(getters.currentProjectId as number|null)) {
      const projects = getters.getCondensedProjects as Project[]
      if (projects.length > 0) {
        commit('SELECT_PROJECT', projects[0].id)
      }
    }
  },

  fetchProject: async ({ commit }, { projectId, showNotification }: ProjectPayload) => {
    commit('SET_LOADING', true)
    try {
      const project: ProjectWithContext = await aedifionApi.Project.getProject(projectId)
      commit('ADD_OR_UPDATE_PROJECT', project)
    } catch (error) {
      if (showNotification) {
        showErrorNotification(`${i18n.global.t('notifications.errors.projects.loadProjects')}`)
      }
      reportError(error)
    } finally {
      commit('SET_LOADING', false)
    }
  },

  fetchProjects: async ({ commit }) => {
    commit('SET_LOADING', true)
    try {
      const projects: ProjectWithContext[] = await aedifionApi.User.getUserProjects()
      commit('SET_PROJECTS', projects)
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.projects.loadProjects')}`)
      reportError(error)
    } finally {
      commit('SET_LOADING', false)
    }
  },

  putProject: async ({ state, commit }, payload: UpdateProject) => {
    const result = await aedifionApi.Project.putProject(payload, state.currentProjectId!)
    commit('UPDATE_PROJECT', result.resource)
  },

  selectProject: async ({ dispatch, commit, getters }, payload: ProjectPayload) => {
    let projectAvailable = storeContainsProject(getters, payload.projectId)
    if (!projectAvailable) {
      await dispatch('fetchProject', payload)
      projectAvailable = storeContainsProject(getters, payload.projectId)
    }
    if (projectAvailable) {
      commit('SELECT_PROJECT', payload.projectId)
    } else {
      commit('SELECT_PROJECT', null)
      validateNotEqual(
        payload.showNotification, false,
        { errorMessage: 'Project does not exist or is not accessible' },
      )
    }
  },
} as ActionTree<ProjectsState, RootState>
