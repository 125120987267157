export function toValidJSON (input: unknown) {
  if (typeof input !== 'string') {
    return {}
  }

  // Otherwise, it will show "" in the json editor
  if (input.trim() === '') {
    return {}
  }

  try {
    const parsed = JSON.parse(input)

    return parsed
  } catch (e) {
    return {}
  }
}
