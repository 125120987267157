import { computed, Ref, ref } from 'vue'
import { acceptHMRUpdate, defineStore } from 'pinia'
import i18n from '@/i18n'
import { useControlsApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import { validateNotNullish } from '@/utils/helpers/validate'

// TODO: refactor this store. For instance `currentProjectHasControlsApp` should
// be a computed that relies on `currentProjectControlsAppCount`.
// `setProjectId()` should be free of side-effects and not be async.
export const useAppStore = defineStore('app', () => {
  const controlsApiStore = useControlsApiStore()

  const currentProjectId: Ref<number|null> = ref(null)
  const currentProjectHasControlsApp = ref(false)
  const currentProjectControlsAppCount: Ref<number|null> = ref(null)

  async function projectHasControlsApp (projectId: number): Promise<boolean> {
    try {
      const controlsAppsFetchResult = await controlsApiStore.getControlsApps({ perPage: 1, projectId })
      currentProjectControlsAppCount.value = controlsAppsFetchResult.meta.total_items
      return controlsAppsFetchResult.meta.total_items > 0
    } catch (error) {
      return false
    }
  }

  const projectId = computed((): number => {
    return validateNotNullish(currentProjectId.value, { errorMessage: i18n.global.t('notifications.errors.no_project_selected') })
  })

  async function setProjectId (value: number): Promise<void> {
    currentProjectId.value = value
    currentProjectControlsAppCount.value = null
    currentProjectHasControlsApp.value = await projectHasControlsApp(value)
  }

  return {
    currentProjectControlsAppCount,
    currentProjectHasControlsApp,
    projectHasControlsApp,
    projectId,
    setProjectId,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot))
}
