<script setup lang="ts">
import DropDown, { type DropDownItem } from '@/components/DropDown.vue'
import { getStatusCodeMeaning } from '@/utils/helpers/controls'
import type { ControlsAppShort } from '@aedifion.io/aedifion-api'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

interface Props {
  controlApps: ControlsAppShort[]|null
  selectedControlsApp: string|null
  selectFirstControlsApp?: boolean
  showAppsState?: boolean
  projectId?: number
  navigationEnabled?: boolean
  fetchMoreControlsApps: () => void
}

const props = withDefaults(defineProps<Props>(), {
  controlApps: null,
  showAppsState: true,
  selectedControlsApp: null,
  selectFirstControlsApp: false,
  navigationEnabled: true,
})

const emit = defineEmits<{
  'searchControlsApps': [search: string]
  'selectControlsApp': [id: string]
}>()

const search = defineModel({
  type: String,
  default: '',
})

const getAppItemTargetRoute = (appId: string) => {
  if (props.navigationEnabled) {
    return { name: 'ai-controls-app-id', params: { project: props.projectId, appId } }
  } else {
    return undefined
  }
}

const { t } = useI18n()

const controlsAppsItems = computed<DropDownItem[]>(() => {
  return (props.controlApps ?? []).map((controlsApp: ControlsAppShort) => {
    const controlsAppIcon = getControlsAppIcon(controlsApp)
    return {
      custom: {
        statusCode: controlsApp.status.code,
        algorithm: controlsApp.algorithm,
        to: getAppItemTargetRoute(controlsApp.id),
        icon: {
          name: controlsAppIcon.name,
          color: controlsAppIcon.color,
        },
      },
      subtitle: controlsApp.status.message,
      title: controlsApp.name,
      value: controlsApp.id,
    }
  })
})

function getControlsAppIcon (app: ControlsAppShort) {
  const isAppActive = !getStatusCodeMeaning('hidden', app.status.code) && getStatusCodeMeaning('active', app.status.code)
  const iconName = isAppActive ? 'fa:fas fa-circle-small' : 'fa:fal fa-circle-small'
  const iconColor = isAppActive ? 'success lighten4' : 'neutral lighten3'

  return {
    name: iconName,
    color: iconColor,
  }
}

function handleSearch (value: string) {
  if (value === '') {
    search.value = ''
    emit('searchControlsApps', '')
  } else {
    emit('searchControlsApps', value)
  }
}
</script>

<template>
  <DropDown
    class="controls-apps-drop-down"
    :items="controlsAppsItems"
    :label="t('controls_app')"
    :show-subtitle="showAppsState"
    :select-first-item="selectFirstControlsApp"
    :load-more-callback="fetchMoreControlsApps"
    :search="search"
    :value="selectedControlsApp"
    @update:search="handleSearch"
    @update:value="emit('selectControlsApp', $event!)"
  >
    <template
      v-for="(_, slot) of $slots"
      #[slot]="
        scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
  </DropDown>
</template>

<style lang="sass" scoped>
:deep(.v-list)
  min-width: 432px
</style>

<i18n lang="json" locale="de">
  {
    "controls_app": "App"
  }
</i18n>
<i18n lang="json" locale="en">
  {
    "controls_app": "App"
  }
</i18n>
