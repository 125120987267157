import type { JSONContent } from '@tiptap/vue-3'

export function getJSONForTextEditor (input: string): JSONContent {
  let jsonContent: JSONContent
  try {
    jsonContent = JSON.parse(input)
  } catch {
    jsonContent = {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              type: 'text',
              text: input,
            },
          ],
        },
      ],
    }
  }

  return jsonContent
}
